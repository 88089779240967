import { Component, ViewChild } from '@angular/core';
import {AnimationType} from "../../../header-slider/header-slider.animations";
import {HeaderSliderComponent} from "../../../header-slider/header-slider.component";
import {Slide} from "../../../header-slider/header-slider.interface";
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent {
  @ViewChild(HeaderSliderComponent) headerslider!: HeaderSliderComponent;

  animationType = AnimationType.Fade;

  slides: Slide[] = [
    {
      headline: "Paintings",
      content: "Our collection features some of the very best works of artists from the African continent as well as from the African diaspora. We proudly present paintings, sculptures, fashion items and accessories with an African touch",
      src:
        "../../assets/images/slider/good/2.jpg"
    },
    {
      headline: "Sculpture",
      content: "This is an effort to provide some sort of meeting place – a market – to exchange ideas about African art, provided through comments, and the sale/purchase of art pieces done by African artists particularly, who may have not otherwise have a good avenue to showcase their talent and make some money.",
      src:
        "../../assets/images/slider/good/1.jpg"
    },
    {
      headline: "Gallery Tours",
      content: "Calling on artists of every genre out there to join us on this quest to explore the African continent and its diaspora and showcase your extraordinary talent to the world. Each piece of art on this page tells a unique story about the artist, the artist’s home and circumstances, the beauty of it all, and even the tragedy in other circumstances.",
      src:
        "../../assets/images/slider/good/3.jpg"
    },
    {
      headline: "Museum Tours",
      content: "Our products represent the best of their creators’ collections, carefully selected by our curator. We trust that our customers will find tremendous satisfaction out of the ownership, and hopefully become repeat customers. This way we hope to be able to uplift the artists so they can create even more works.  ",
      src:
        "../../assets/images/slider/good/4.jpg"
    }
  ];
  slideOptions = {
    nav: false,
    dots: true,
    loop: true,
    margin: 100,
    responsiveClass: true,
    // responsive object contains responsive options.
    responsive: {
      0: {
        items: 1,
        dots: false
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };
  constructor() { }

  ngOnInit(): void {
  }

}
