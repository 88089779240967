import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CartService } from 'app/_services/cart.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { ProductsService } from 'app/_services/products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'app/_services/alert.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-museum-visit',
  templateUrl: './museum-visit.component.html',
  styleUrls: ['./museum-visit.component.css']
})
export class MuseumVisitComponent implements OnInit {
  videoSources: any = [];
  moviePoster: string = "../../assets/images/movie-poster.png";
  mainImage: string = '';
  product: any;
  productId: string = '';
  videojs: any;
  player: any;
  videoUrl: string = '';
  artist: any = 'Unknown';
  category: any = 'Unknown';
  prodtype: any = 'Unknown';
  images: any[] = [];
  privateImages: any[] = [];
  privateVideos: any[] = [];
  productCarouselImages: any[] = [];
  productsCarousel: any[] = [];
  currentImage: any = '';

  playerOptions: any  =  {
    controlBar: {
      children: [
         'playToggle',
         'progressControl',
         'volumePanel',
         'qualitySelector'
      ],
       fullscreenToggle: true,
       responsive: true
   },
    sources: '',
    poster: '../../assets/images/movie-poster.png', name : 'Movie Poster'
  };
  slideOptions = {
    nav: false,
    dots: false,
    loop: true,
    margin: 100,
    responsiveClass: true,
    // responsive object contains responsive options.
    responsive: {
      0: {
        items: 2,
        dots: false
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  productGalleryOptions = {
    nav: false,
    dots: false,
    loop: false,
    margin: 10,
    responsiveClass: true,
    // responsive object contains responsive options.
    responsive: {
      0: {
        items: 2,
        dots: false
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };
  constructor(
    private productsService: ProductsService,
    private globalChangesService: GlobalChangesService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.showSpinner();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.productId = params.get('id') || '';
      this.getProduct(this.productId);
    });
  }

  showSpinner(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        color: 'white',
        fullScreen: false
      }
    );
  }
  hideSpinner(){
    this.spinner.hide();
  }
  showSpecificSpinner(name: string) {
    console.log('show spinner' + name);
    this.spinner.show(name, {
      type: 'ball-clip-rotate',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#98570c',
      fullScreen: false
    });
  }

  hideSpecificSpinner(name: string) {
     this.spinner.hide(name);
  }
  getProduct(id: string){
    if (this.productId !== ''){
      this.showSpecificSpinner('product-image');
      console.log('get product no ' + id);
      this.productsService.getProduct(id).pipe(first()).subscribe((result: any) => {
        // tslint:disable-next-line: no-non-null-assertion
        // tslint:disable-next-line: no-non-null-assertion
        this.product = result.data;
        console.log(this.product);
        this.getProductsFromCategory(this.product.prodtypeid);
        this.artist = result.included.filter((elem: any) => elem.type === 'artist')[0] || 'Unknown';
        this.prodtype = result.included.filter((elem: any) => elem.type === 'prodtype')[0] || 'Unknown';
        this.category = result.included.filter((elem: any) => elem.type === 'category')[0] || 'Unknown';

        this.product.images.forEach((image: any) => {
          this.showSpecificSpinner(`carousel-image-${image.id}`);
          this.images.push(
            {url: `https://api.artourafrica.com/api/imgthumb/?size=500&id=${image.id}`,
            id: image.id}
            );
        });

        this.product.privateimages.forEach((image: any) => {
          this.showSpecificSpinner(`carousel-image-${image.id}`);
          this.privateImages.push(
            {url: `https://api.artourafrica.com/api/imgthumb/?size=500&id=${image.id}`,
            id: image.id,
            type: 'image'}
            );
        });
        if (this.privateImages.length > 0){
          this.mainImage = 'image';
        }
        console.log(this.privateImages);
        this.product.privatevideos.forEach((video: any) => {
          this.showSpecificSpinner(`carousel-video-${video.id}`);
          this.privateVideos.push({
            url: `https://api.artourafrica.com/api/videothumb/?id=${video.id}`,
            id: video.id,
            type: 'video',
            videoSources: [{
              src  : `https://api.artourafrica.com/api/videofile?id=${video.id}&size=low`,
              type : 'video/mp4',
              label: 'SD'
            },
            {
              src  : `https://api.artourafrica.com/api/videofile?id=${video.id}&size=high`,
              type : 'video/mp4',
              label: 'HD',
              selected: true
            }]
          });
        });

        console.log(this.privateVideos);
        if (this.privateVideos.length > 0 ){
          //this.currentImage = this.privateVideos[0].url;
          console.log('THIS CURRENT IMAGE ' + this.privateVideos[0].url);
          this.moviePoster = this.privateVideos[0].url;
          this.loadVideo(this.privateVideos[0]);
          this.mainImage = 'video';
        }else{
          this.currentImage = this.privateImages[0].url;
          console.log('THIS CURRENT IMAGE ' + this.currentImage);
        }
        console.log(this.privateVideos);
        this.currentImage = this.images[0].url;
        this.hideSpinner();
      });
    }else{
      console.log('NO PRODUCT');
    }
  }
  getProductsFromCategory(prodtypeid: number){
      let filters = {
        prodtypeid: [prodtypeid]
      }
      this.productsService.getProducts(filters).pipe(first()).subscribe((result: any) => {
        this.productsCarousel = result.data;
        console.log(this.productsCarousel);
        this.productsCarousel = this.productsCarousel.sort(() => 0.5 - Math.random());
        this.productsCarousel  = this.productsCarousel.slice(0, 6);
        console.log(this.productsCarousel);
        this.productsCarousel.forEach((product) => {
          this.showSpecificSpinner('product-carousel-' + product.id);
          this.productCarouselImages.push(`https://api.artourafrica.com/api/imgthumb/?size=300&id=${product.images[0].id}`);
        });
        console.log(this.productCarouselImages);
      })
  }
  changeImage(image: string){
    this.currentImage = image;
  }
  addToCart(product: any){
    console.log('add to cart');
    // tslint:disable-next-line: max-line-length
    this.cartService.addItem({productid: product.id, title: '24HrsTicket ' + product.title, price: product.price, img: product.images[0].id}, 1);
  }
  changeProduct(id: string){
    this.router.navigateByUrl(`/product/${id}`);
  }
  loadVideo(video: any){

    console.log('src changed');
    this.videoSources = video.videoSources;
    console.log('videoSources');
    console.log(video);
    this.playerOptions.poster = video.url;
    this.moviePoster = video.url;
    console.log('THIS CURRENT IMAGE ' + video.url)
    this.mainImage = 'video';
  }
  loadImage(src: string){
    this.mainImage = 'image';
    console.log('IMAGE LOAD' + src);
    this.currentImage = src;
  }
  rightClickMenu($event: Event){
    $event.preventDefault();
   // alert('Dont`t try to steal!');
    //this.alertService.error("Right Menu Disabled");
  }
}
