<div id = 'addressForm' >
  <div class="form-wrapper">
    <form [formGroup]="addressForm" (ngSubmit)="submit()">

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <input formControlName="name" id= "name" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.name.invalid }" placeholder="Full name">
            <div *ngIf="f.name.invalid && submitted">
              <div *ngIf="f.name.errors?.required" class="invalid-feedback">Name is required.</div>
              <div *ngIf="f.name.errors?.minlength" class="invalid-feedback">Name must be at least 3 characters long.</div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <input formControlName="phone" id= "phone" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.phone.invalid }" placeholder="Phone">
            <div *ngIf="f.phone.invalid && submitted">
              <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Phone is required.</div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <input formControlName="address1" id= "address1" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.address1.invalid }" placeholder="Address">
            <div *ngIf="f.address1.invalid && submitted">
              <div *ngIf="f.address1.errors?.required" class="invalid-feedback">Address is required.</div>
              <div *ngIf="f.address1.errors?.minlength" class="invalid-feedback">Address must be at least 6 characters long.</div>
            </div>
          </div>
          <div class="form-group">
            <input formControlName="address2" id= "address2" type="text" class="form-control" placeholder="Address 2">
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group select-wrapper select-wrapper-sm">
                <ng-select class="form-control custom-ng " [ngClass]="{'is-invalid': submitted && f.countryid.invalid }"
                [items]="countries"
                placeholder={{countryPlaceholder}}
                (clear) = "countryChanged()"
                (change) = "countryChanged()"
                typeToSearchText =" Type to search.."
                [minTermLength] = 3
                [typeahead] = countryInput
                [clearSearchOnAdd] = true
                [closeOnSelect] = true
                [searchable]="true"
                bindLabel="name"
                bindValue="id"
                loadingText="Loading..."
                formControlName="countryid">
                <ng-option [value]=currentUser.country.id>{{currentUser.country.name}}</ng-option>
            </ng-select>
            <div *ngIf="f.countryid.invalid && submitted">
              <div  *ngIf="f.countryid.errors?.required" class="invalid-feedback">Country is required.</div>
            </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group select-wrapper select-wrapper-sm" >
                <ng-select class="form-control custom-ng" [ngClass]="{'is-invalid': submitted && f.regionid.invalid }"
                [items]="regions"
                placeholder={{regionPlaceholder}}
                (clear) = "regionChanged()"
                (change) = "regionChanged()"
                typeToSearchText =" Type to search.."
                [minTermLength] = 3
                [typeahead] = regionInput
                [searchable]="true"
                bindLabel="name"
                bindValue="id"
                loadingText="Loading..."
                formControlName="regionid">
                <ng-option [value]=currentUser.region.id>{{currentUser.region.name}}</ng-option>
            </ng-select>
            <div *ngIf="f.regionid.invalid && submitted">
              <div  *ngIf="f.regionid.errors?.required" class="invalid-feedback">Region is required.</div>
            </div>
              </div>
            </div>
            </div>
            <div class='row'>
              <div class="col-sm-6">
                <div class="form-group select-wrapper select-wrapper-sm">
                  <ng-select class="form-control custom-ng" [ngClass]="{'is-invalid': submitted && f.cityid.invalid }"
                  [items]="cities"
                  placeholder={{cityPlaceholder}}
                  (clear) = "cityChanged()"
                  (change) = "cityChanged()"
                  typeToSearchText =" Type to search.."
                  [minTermLength] = 3
                  [typeahead] = cityInput
                  [searchable]="true"
                  bindLabel="name"
                  bindValue="id"
                  loadingText="Loading..."
                  formControlName="cityid">
              </ng-select>
              <div *ngIf="f.cityid.invalid && submitted">
                <div  *ngIf="f.cityid.errors?.required" class="invalid-feedback">City is required.</div>
              </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input formControlName="postcode" id= "postcode" type="text" class="form-control" placeholder="ZipCode" [ngClass]="{'is-invalid': submitted && f.postcode.invalid }">
                  <div *ngIf="f.postcode.invalid && submitted">
                    <div  *ngIf="f.postcode.errors?.required" class="invalid-feedback">PostCode is required.</div>
                  </div>
            </div>
          </div>
        </div>
        </div>
    </div>
      <div class='col-lg-12 form-buttons-row'>
        <span class="btn btn--sm btn--invert" (click)="cancel()" style="float:left;" >Cancel</span>
        <span class="btn btn--sm" (click)="submit()" style="float:right;" >Save</span>
      </div>
    </form>
    </div>
</div>
