<div class="page-content" style="min-height: 226px;">

  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a [routerLink]="['/']" routerLinkActive="router-link-active" >Home</a></li>
        <li><span>My account</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <div class="row">

        <div class="col-md-3 aside aside--left">
          <div class="list-group">
            <a routerLink="/profile" class="list-group-item ">Account Details</a>
            <a routerLink="/addresses" class="list-group-item active">My Addresses</a>
            <a routerLink="/orders" class="list-group-item">My Order History</a>
          </div>
        </div>
        <div class="col-md-9 aside">
          <ngx-spinner>
            <p class="loading">Please Wait...</p>
          </ngx-spinner>
          <h1 class="mb-3">My Addresses</h1>
          <div *ngIf="addresses && addresses.length > 0 && !loading; else noAddreses">
          <div  *ngFor =" let address of addresses">
            <div class="row address-row" id="address-row-{{address.id}}" >
            <div class='col-sm-7'>
              <span class='address-title'>
                {{address.name}} {{address.phone}}
              </span><br>
              <span class='address-desc'>
                {{address.address1}}
              </span><br>
              <span class='address-desc'>
                {{address.address2}} {{getCityName(address)}},{{getRegionName(address)}},{{getCountryName(address)}}
              </span>
          </div>
          <div class='col-sm-5' id='row-buttons-{{address.id}}'>
              <ul class='table-buttons'>
                <li>
                  <span  class="btn btn--sm" (click)= showForm(address.id) >Edit</span>
                </li>
                <li>
                  <span (click) = "deleteAddressDialog(address.id, true)" class="btn btn--sm delete-button">Delete</span>
                </li>
              </ul>
          </div>
          <div class='col-sm-3 confirm-buttons' id='confirm-buttons-{{address.id}}' style="display:none">
            <div class='confirm-dialog'>Are you sure?</div>
            <ul class='table-buttons'>
              <li>
                <span (click)="deleteAddress(address.id)" class="btn btn--sm btn--grey">Yes</span>
              </li>
              <li>
                <span  (click) = "deleteAddressDialog(address.id, false)" class="btn btn--sm btn--invert delete-button">No</span>
              </li>
            </ul>
          </div>
            </div>
          <div id="form-{{address.id}}" class='address-form' style="display:none">
            <app-address-form
            [address]= address
            [fullCountryName]= getCountryName(address)
            [fullRegionName]= getRegionName(address)
            [fullCityName]= getCityName(address)
            (close)='closeForm($event)'
            (addressUpdated)='getAddresses()'>
          </app-address-form>
          </div>

          </div>
    </div>
    <ng-template #noAddreses>
    <div class="row no-addresses-container" *ngIf="!loading">
      <div class="col-sm-12 my-auto">
        <span><h3>You don`t have any addresses</h3></span>
        <span><p>You can add one pressing 'Add Address' button in order to complete the address form</p></span>
      </div>
   </div>
    </ng-template>

    <div class='row' *ngIf="!formLoaded">
<div class="col-sm-12"><span  class="btn add-btn" (click)="showForm('add')">Add Address</span></div>
<div class = 'form-row-add'>
<div id="form-add" class='address-form' style="display:none">
  <app-address-form  (close)='closeForm($event)' (addressUpdated)="getAddresses(); closeForm('add')"></app-address-form>
</div>
</div>
</div>
    </div>
  </div>
</div>
</div>
</div>
