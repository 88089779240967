<div class="page-content" style="min-height: 226px;" >
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><span>Country</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <!-- Two columns -->
      <!-- Page Title -->
      <div class="page-title text-center">
        <h1>ARTWORKS</h1>
      </div>
      <!-- /Page Title -->
      <!-- Filter Row -->
      <div class="filter-row">
        <div class="row">
          <div class="items-count">{{totalItems}} item(s)</div>
          <div class="select-wrap d-none d-md-flex">
            <div class="select-label">SORT:</div>
            <div class="select-wrapper select-wrapper-xxs">
            <select class="form-control input-sm" (change)="sortApply($event)">
              <option value=""><i class="fa fa-email"></i></option>
              <option value="price">Price asc</option>
              <option value="-price">Price desc</option>
            </select>
            </div>
          </div>
        </div>
      </div>
      <!-- /Filter Row -->
      <div class="row">
        <!-- Left column -->
        <div id ="filters-mobile" class="col-lg-4 aside aside--left filter-col filter-mobile-col filter-col--sticky js-filter-col filter-col--opened-desktop filter-col--init " [ngClass]="{'filter-col--opened':filtersMobileShow}" style="left: -652.5px;">
          <div class="filter-col-content filter-mobile-content ">
            <div class="sidebar-block">
              <div class="sidebar-block_title">
                <span>Current selection</span>
              </div>
              <div class="sidebar-block_content">
                <div class="selected-filters-wrap">
                  <ul class="selected-filters">
                    <li *ngFor="let artistFilter of appliedFilters.artists" (click)="removeFilter(artistFilter,'artist')"><span>{{getArtistById(artistFilter)}}</span></li>
                    <li *ngFor="let categoryFilter of appliedFilters.categories" (click)="removeFilter(categoryFilter,'category')"><span>{{getCategoryById(categoryFilter)}}</span></li>
                  </ul>
                  <div class="d-flex flex-wrap align-items-center">
                    <div *ngIf = "appliedFilters.artists.length > 0 || appliedFilters.categories.length >0">
                      <span (click)="resetFilters()" class="clear-filters"><span>Clear All</span></span>
                    </div>

                    <div class="selected-filters-count ml-auto d-none d-lg-block">Selected <span>{{filtersSelectedCount}} filters</span></div>
                  </div>
                </div>
              </div>
            </div>
<!--             <div class="sidebar-block">
              <div class="sidebar-block_title">
                <span>Current selection</span>
              </div>
              <div class="sidebar-block_content">
                <div class="selected-filters-wrap">
                  <ul class="selected-filters">
                    <li><a [routerLink]="[]">Picasso</a></li>
                    <li><a [routerLink]="[]">Paintings</a></li>
                    <li><a [routerLink]="[]">Above $200</a></li>
                  </ul>
                  <div class="d-flex flex-wrap align-items-center">
                    <a [routerLink]="[]" class="clear-filters"><span>Clear All</span></a>
                    <div class="selected-filters-count ml-auto d-none d-lg-block">Selected <span>6 items</span></div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="sidebar-block d-filter-mobile">
              <h3 class="mb-1">SORT BY</h3>
              <div class="select-wrapper select-wrapper-xs">
                <select class="form-control input-sm" (change)="sortApply($event)">
                  <option value=""></option>
                  <option value="price">Price asc</option>
                  <option value="-price">Price desc</option>
                </select>
              </div>
            </div>

            <div *ngIf = "filters"  class="sidebar-block filter-group-block" [ngClass]="categoriesFilterExpand  ? 'open' : 'collapsed' " >
              <div class="sidebar-block_title" (click)="toggleCategory('categories')">
                <span>Country of Origin</span>
                <span class="toggle-arrow"><span></span><span></span></span>
              </div>
              <div class="sidebar-block_content ">
                <ul class="category-list" *ngFor="let category of filters.categories">
                  <li (click)="addFilter('selectedCategories',category.id)" [class.active]="selectedCategories.includes(category.id)"><a>{{category.title}}</a></li>
                </ul>
              </div>
            </div>
            <div *ngIf = "filters"  class="sidebar-block filter-group-block" [ngClass]="artistsFilterExpand ? 'open' : 'collapsed' " >
              <div class="sidebar-block_title" (click)="toggleCategory('artists')">
                <span>Artists</span>
                <span class="toggle-arrow"><span></span><span></span></span>
              </div>
              <div class="sidebar-block_content ">
                <ul class="category-list" *ngFor="let artist of filters.artists">
                  <li (click)="addFilter('selectedArtists',artist.id)" [class.active]="selectedArtists.includes(artist.id)"><a>{{artist.name}}</a></li>
                </ul>
              </div>
            </div>
<!--             <div *ngIf = "filters"  class="sidebar-block filter-group-block" [ngClass]="pricesFilterExpand  ? 'open' : 'collapsed' ">
              <div class="sidebar-block_title" (click)="toggleCategory('prices')">
                <span>Price range</span>
                <span class="toggle-arrow"><span></span><span></span></span>
              </div>
              <div class="sidebar-block_content">
                <ul class="category-list" *ngFor="let priceRange of filters.prices">
                  <li (click)="addFilter('selectedPriceRanges',priceRange.id)" [class.active]="selectedPriceRanges.includes(priceRange.id)"><a>{{priceRange.name}}</a></li>

                </ul>
              </div>
            </div> -->
            <span (click)="applyFilters()" class="btn btn--md btn--grey"><span>Apply Filters</span></span>

          </div>
        </div>
        <!-- filter toggle -->
        <div class="filter-toggle js-filter-toggle">
          <div class="loader-horizontal js-loader-horizontal">
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%"></div>
            </div>
          </div>
          <span class="filter-toggle-icons js-filter-btn"><i class="icon-filter"></i><i class="icon-filter-close"></i></span>
          <span class="filter-toggle-text">
          <div *ngIf="!filtersMobileShow" (click)="showFilters()" class="filter-btn-open js-filter-btn">REFINE & SORT</div>
          <div  *ngIf="filtersMobileShow" (click)="resetFilters()" class="filter-btn-close js-filter-btn">RESET</div>
          <div *ngIf="filtersMobileShow" (click)="applyFilters()" class="filter-btn-apply js-filter-btn">APPLY & CLOSE</div></span>
        </div>
        <!-- /Left column -->
        <!-- Center column -->
        <div class="col-lg aside" *ngIf="!loading">
          <div class="prd-grid-wrap">
            <!-- Products Grid -->
            <div class="prd-grid product-listing data-to-show-3 data-to-show-md-3 data-to-show-sm-2 js-category-grid" data-grid-tab-content="" *ngIf="products.length>0; else noProducts">

              <div *ngFor="let product of products" class="prd prd--style2 prd-labels--max prd-labels-shadow prd-w-lg" (load)=showSpecificSpinner(product.id)>

                <div  class="prd-inside">
                  <ngx-spinner name="{{product.id}}" ></ngx-spinner>
                <div  class="prd-img-area">

                  <a  [routerLink]="['/product', product.id]"  class="prd-img image-hover-scale image-container">
                    <img  *ngIf="product.images.length > 0" src="https://api.artourafrica.com/api/imgthumb/?size=280&id={{product.images[0].id}}" alt="Artwork Title" loading="lazy" class="js-prd-img" (load)="hideSpecificSpinner(product.id)">
                    <img *ngIf="product.images.length == 0" src="../../assets/images/nophoto.jpg" alt="Artwork Title" loading="lazy" class="js-prd-img" (load)="hideSpecificSpinner(product.id)">
                  </a>
                </div>
                <div  class="prd-info">
                  <div  class="prd-info-wrap">
                    <div  class="prd-tag">
                      <p>{{getCategoryById(product.categoryid)}}</p>
                    </div>
                    <h2  class="prd-title"><a  [routerLink]="['/product', product.id]">{{product.title}}</a></h2>
                  </div>
                  <div  class="">
                    <div  class="prd-price">
                      <div  class="price-old">$ {{(product.price*1.15).toFixed(1)}}</div>
                      <div  class="price-new">$ {{product.price}}</div>
                    </div>
                    <div  class="prd-action">
                      <div  class="prd-action-left">
                          <span (click)="addToCart(product)" class="btn js-prd-addtocart">Add To Cart</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </div>

            <ul class="pagination shop-list" *ngIf="noOfPages > 1">
              <li *ngFor="let e of [].constructor(noOfPages); let i = index"><span (click)="changePage(i+1)" style="cursor:pointer;">{{i+1}}</span></li>
            </ul>

          </div>
        </div>
        <ng-template class="col-lg aside"  #noProducts>
          <div class="page404-bg" style="min-height: 226px;">
            <div class="page404-text">
              <div class="txt3"><i class="icon-shopping-bag"></i></div>
              <div class="txt4">Unfortunately, there are no products<br>matching the selection</div>
            </div>
          </div>
        </ng-template>
        <!-- /Center column -->
      </div>
      <!-- /Two columns -->
    </div>
  </div>

</div>
