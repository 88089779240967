import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';



@Injectable({ providedIn: 'root' })



export class CitiesService {
    constructor(private http: HttpClient) { }


    getCountries(search: string) { // get<Contact>
      return this.http.get(`${environment.apiUrl}item/country/?q=${search}`);
    }
    getRegions(countryId: number, search: string) { // get<Contact>
      return this.http.get(`${environment.apiUrl}item/region/?filter[countryid]=${countryId}&q=${search}`);
    }
    getCities(regionId: number, search: string) { // get<Contact>
      return this.http.get(`${environment.apiUrl}item/city/?filter[regionid]=${regionId}&q=${search}`);
    }


}
