import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { UserService } from 'app/_services/user.service';
import { Subscription, Observable, Subject } from 'rxjs';
import {MustMatch} from '../../_helpers/must-match.validator';
import {CitiesUpdateService} from '../../_services/cities-update.service';
import { Router } from '@angular/router';
import {User} from '../../_models/user';
import { first } from 'rxjs/operators';
import { AlertService } from 'app/_services/alert.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup = new FormGroup({});
  submitted = false;
  loading = false;
  currentUser!: any;
  currentUserSubscription!: Subscription;
  updateSuccess = false;
  countries: any = [];
  regions: any = [];
  cities: any = [];
  duplicateEmail = false;
  countriesSubscription!: Subscription;
  regionsSubscription!: Subscription;
  citiesSubscription!: Subscription;
  countryInput = new Subject<string>();
  regionInput = new Subject<string>();
  cityInput = new Subject<string>();
  countryPlaceholder = 'Select Country';
  regionPlaceholder = 'Select Region';
  cityPlaceholder = 'Select City';
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private citiesUpdateService: CitiesUpdateService,
    private router: Router,
    private alertService: AlertService,
    private globalChangesService: GlobalChangesService) {
      this.countryInput.subscribe((countryTerm) => {
          this.countriesUpdate(countryTerm);
      });
      this.regionInput.subscribe((regionTerm) => {
        this.regionsUpdate(regionTerm);
    });
      this.cityInput.subscribe((cityTerm) => {
         this.citiesUpdate(cityTerm);
  });
  }
  ngOnInit(): void {
    //this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}') ;
    this.currentUserSubscription = this.globalChangesService.accountDetails.subscribe(accountDetails => this.currentUser = accountDetails);
    console.log('profile page user');
    console.log(this.currentUser);
    this.countriesSubscription = this.citiesUpdateService.countries.subscribe(countries => this.countries = countries);
    this.regionsSubscription = this.citiesUpdateService.regions.subscribe(regions => this.regions = regions);
    this.citiesSubscription = this.citiesUpdateService.cities.subscribe(cities => this.cities = cities);



    this.profileForm = this.fb.group(
      {
        firstname: ['', [Validators.required, Validators.minLength(3)]],
        lastname: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],
        address1: ['', [Validators.required, Validators.minLength(3)]],
        address2: [''],
        countryid: ['', [Validators.required]],
        regionid: ['', [Validators.required]],
        cityid: ['', [Validators.required]],
        phone: ['', [Validators.required, Validators.minLength(6)]],
        postcode: ['', [Validators.required]],
        usertype: ['', [Validators.required]],
        vat: [''],
        company: [''],
        description: [''],
        shortdescription: ['']
      }
    );
    // tslint:disable-next-line: no-unused-expression
    this.populateForm();
    this.profileForm.disable();
  }

  get f(): any{
    return this.profileForm.controls;
  }

    populateForm(){
      this.profileForm.setValue(
        {
          firstname: this.currentUser.firstname,
          lastname: this.currentUser.lastname,
          email: this.currentUser.email,
          address1: this.currentUser.address1,
          address2: this.currentUser.address2,
          countryid: this.currentUser.country.id,
          cityid:this.currentUser.city.id,
          regionid:this.currentUser.region.id,
          phone: this.currentUser.phone,
          postcode: this.currentUser.postcode,
          usertype: this.currentUser.usertype,
          vat: this.currentUser.vat,
          company: this.currentUser.company,
          description: this.currentUser.description,
          shortdescription: this.currentUser.shortdescription
        }
      );
      this.countryPlaceholder = this.regionPlaceholder = this.cityPlaceholder = '';
      this.countries = [{id:this.currentUser.country.id, name: this.currentUser.country.name}];
      this.regions = [{id:this.currentUser.region.id, name: this.currentUser.region.name}];
      this.cities = [{id:this.currentUser.city.id, name: this.currentUser.city.name}];

    }

  countriesUpdate(searchString: string): void{
    console.log(searchString);
    this.citiesUpdateService.updateCountriesList(searchString);
    console.log(this.countries);
}

regionsUpdate( searchString: string): void{
  console.log(searchString);
  console.log('selected country');
  console.log(this.f.countryid.value);
  const countryId = this.f.countryid.value;
  this.citiesUpdateService.updateRegionsList(countryId, searchString);
  console.log(this.regions);
}

citiesUpdate( searchString: string): void{
  console.log(searchString);
  console.log('selected region');
  console.log(this.f.regionid.value);
  const regionId = this.f.regionid.value;
  this.citiesUpdateService.updateCitiesList(regionId, searchString);
  console.log(this.cities);
}

countryChanged(): void{
console.log('COUNTRY CHANGED');
if (!this.f.countryid.value){
  this.countryPlaceholder = 'Select Country';
}else{
  this.countryPlaceholder = '';
}

console.log(this.f.countryid.value);
this.f.regionid.reset();
this.regionChanged();
this.countries = [];
// this.f.cityid.reset();
}

regionChanged(): void{
if (!this.f.regionid.value){
  this.regionPlaceholder = 'Select Region';
}else{
  this.regionPlaceholder = '';
}
console.log('REGION CHANGED');
console.log(this.f.regionid.value);
this.f.cityid.reset();
this.cityChanged();
}

cityChanged():void{
if (!this.f.cityid.value){
  this.cityPlaceholder = 'Select City';
}else{
  this.cityPlaceholder = '';
}
}

enableForm(){
  this.profileForm.enable();
}
submit(): void{
  console.log(typeof this.profileForm.value);
 // alert(this.profileForm.value);
  this.submitted = true;
  if (this.profileForm.invalid) {
     console.log('form invalid');
     console.log(this.profileForm.errors);
    // alert('Please fill all the required fields!');
     return ;
   } else {
     const formValues = this.profileForm.value;
     formValues.id = this.currentUser.id
     const formData: any = new FormData();
     console.log(formValues);
     //formValues.firstname ='';
     for (const [key, value] of Object.entries(formValues)) {
       console.log(`${key}: ${value}`);
       if(value !== ''){
        formData.append(key, value);
       }

     }
     console.log(formData);
     this.userService.update(formValues)
         .pipe(first())
         .subscribe(
             (data: any) => {

                 if (data.success){
                   this.updateSuccess = true;
                   this.alertService.success('Profile Successful Updatet!', true);
                   this.profileForm.disable();
                 }else{
                   const saveErrors = data.saveErrors;
                   console.log(saveErrors);
                   const duplicateEmailError = saveErrors.find((elem: any) => elem.validator === 'unique');
                   if (duplicateEmailError){
                     this.duplicateEmail = true;
                   }
                   return;
                 }

             },
             error => {
                // this.alertService.error(error);
                 this.loading = false;
             });
   }
}


}
