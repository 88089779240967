<div class="page-content">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="index.html">Home</a></li>
        <li><span>Gallery</span></li>
      </ul>
    </div>
  </div>
  <div class="holder fullwidth">
    <div class="container px-0">
      <!-- Page Title -->
      <div class="page-title text-center">
        <div class="title">
          <h1>GALLERIES</h1>
        </div>
      </div>
      <!-- /Page Title -->

      <div class="gallery-wrapper">
        <div class="gallery js-gallery-isotope row no-gutters">
          <div class="col col-sm-9 col-md-6 gallery-item category3 category1">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 1</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category2">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 2</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category1">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 3</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category2 category3">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 4</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category2">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 5</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category3">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 6</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category1">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 7</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category2">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 8</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
          <div class="col col-sm-9 col-md-6 gallery-item category1">
            <div class="gallery-item-image"><img src="./assets/images/gallery-2.jpg" alt=""></div>
            <div class="gallery-item-caption">
              <h3 class="gallery-item-subtitle">GALLERY 9</h3>
              <h4 class="gallery-item-title">Galery Details</h4>
              <div class="gallery-item-links">
                <a [routerLink]="[]"  class="gallery-item-link" data-fancybox="gallery" data-caption="Lorem Ipsum Dolor"><i class="icon-zoom-in"></i></a>
                <a [routerLink]="[]" class="gallery-item-link"><i class="icon-arrow-right-bold"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mt-3 mt-md-5 justify-content-center align-items-start">
    <ul class="pagination mt-0">
      <li class="active"><a [routerLink]="[]">1</a></li>
      <li><a [routerLink]="[]">2</a></li>
      <li><a [routerLink]="[]">3</a></li>
      <li><a [routerLink]="[]">4</a></li>
      <li><a [routerLink]="[]">5</a></li>
    </ul>
  </div>
</div>

