<div class="page-content" style="min-height: 226px;">
    <div class="holder breadcrumbs-wrap mt-0">
      <div class="container">
        <ul class="breadcrumbs">
          <li><a href="index.html">Home</a></li>
          <li><span>PaymentSuccess</span></li>
        </ul>
      </div>
    </div>
    <div class="holder">
      <div class="container">
        <h1 class="text-center">Payment Successful!</h1>
<ng-container>
    <div class="container">
    <div class="thanks-bg" style="min-height: 256px;">
<p class="text-center">it may take a few minutes before you can see this command in your order list</p>
    </div>
    <div class="thanks-info text-center">
      <a class="btn" routerLink="/home">Continue Shopping</a>
    </div>
  </div>
  </ng-container>
  </div>
  </div>
  </div>
  