<div class="page-content" *ngIf="artist">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/artists">Artists</a></li>
        <li><span>{{artist.name}}</span></li>
      </ul>
    </div>
  </div>
  <div>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)">
      <p class="loading">Please Wait...</p>
    </ngx-spinner>
  <div class="holder">
    <div class="container js-prd-gallery" id="prdGallery">
      <div class="row prd-block prd-block--prv-bottom prd-block--prv-double">
        <div class="col-md-5 col-lg-5 aside--sticky js-sticky-collision">
          <div class="aside-content">
            <!-- Product Gallery -->
            <div class="mb-2 js-prd-m-holder"></div>
            <div class="prd-block_main-image">
              <div class="prd-block_main-image-holder" id="prdMainImage">
                <div class="product-main-carousel js-product-main-carousel" data-zoom-position="inner">
                  <div data-value="Beige">
                    <ngx-spinner name="artist-image" ></ngx-spinner>
                    <span class="prd-img">
                      <img src="{{environment.apiUrl}}imgthumb/?size=300&id={{artist.picture.id}}" id="artist-picture" class="elzoom" alt=""  (load)="hideSpecificSpinner('artist-image')">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Product Gallery -->
          </div>
        </div>
        <div class="col-md-6 col-lg-6 mt-1 mt-md-0">
          <div class="prd-block_info prd-block_info--style1" >
            <div class="prd-holder prd-block_info_item order-0 mt-15 mt-md-0">
              <div class="prd-block_title-wrap">
                <h1 class="prd-block_title">{{artist.name}}</h1>
              </div>
            </div>
            <div class="prd-block_description prd-block_info_item ">
              <h3>Short description</h3>
              <p>{{artist.shortdescription}}</p>
              <div class="mt-1"></div>
            </div>
            <div class="prd-block_info_item prd-block_info-when-arrives d-none" data-when-arrives>
              <div class="prd-block_links prd-block_links m-0 d-inline-flex">
                <i class="icon-email-1"></i>
                <div><a [routerLink]="[]" data-follow-up="" data-name="Oversize Cotton Dress" class="prd-in-stock" data-src="#whenArrives">Inform me when the item arrives</a></div>
              </div>
            </div>
            <div class="prd-block_info_item">
              <ul class="prd-block_links list-unstyled">
                <li><i class="icon-delivery-1"></i><a [routerLink]="[]" data-fancybox class="modal-info-link" data-src="#deliveryInfo">More of this works</a></li>
                <li><i class="icon-email-1"></i><a [routerLink]="[]" data-fancybox class="modal-info-link" data-src="#contactModal">Contact this artist</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder prd-block_links-wrap-bg d-none d-md-block">
    <div class="prd-block_links-wrap prd-block_info_item container mt-2 mt-md-5 py-1">
      <div class="prd-block_link"><span><i class="icon-call-center"></i>24/7 Support</span></div>
      <div class="prd-block_link">
        <span>Use promocode AFRICART to get 15% discount!</span>
      </div>
      <div class="prd-block_link"><span><i class="icon-delivery-truck"></i> Fast Shipping</span></div>
    </div>
  </div>
  <div class="holder mt-2 mt-md-5">
    <div class="container">
      <div class="panel-group panel-group--style1 prd-block_accordion" id="productAccordion">
        <div class="panel">
          <div class="panel-heading active">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#productAccordion" [routerLink]="[]">
                Description</a>
            </h4>
          </div>
          <div id="collapse2" class="panel-collapse collapse show">
            <div class="panel-body">
                {{artist.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="holder" *ngIf="productsCarousel.length>0">
    <div class="container">
      <div class="title-wrap text-center">
        <h2 class="h1-style">More from <span style="color:#98570c;">{{artist.name}}</span></h2>

      </div>
      <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding']" *ngIf="productsCarousel.length>0">
        <div class="item" *ngFor="let product of productsCarousel; let i = index">
          <div class="card" style="width: 18rem;">
            <ngx-spinner name="artist-carousel-{{product.id}}" ></ngx-spinner>
            <div class='image-container'>

            <img class="card-img-top" [src]="productCarouselImages[i]" alt="Card image cap" (load)="hideSpecificSpinner('artist-carousel-'+product.id)">
          </div>
            <div class="card-body">
              <h5 class="card-title">{{product.title}} </h5>
              <a [routerLink]="['/product', product.id]" class="btn btn-primary">Details</a>
            </div>
          </div>
        </div>
      </owl-carousel>
    </div>
  </div>
</div>

