<div class="page-content" style="min-height: 226px;">

  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a [routerLink]="['/']" routerLinkActive="router-link-active" >Home</a></li>
        <li><span>Orders History</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <div class="row">

        <div class="col-md-3 aside aside--left">
          <div class="list-group">
            <a routerLink="/profile" class="list-group-item ">Account Details</a>
            <a routerLink="/addresses" class="list-group-item ">My Addresses</a>
            <a routerLink="/orders" class="list-group-item active">My Order History</a>
          </div>
        </div>
        <div class="col-md-9 aside">
          <h1 class="mb-3">Order History</h1>
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-order-history">
              <thead>
                <tr>
                  <th scope="col"># </th>
                  <th scope="col">Order Number</th>
                  <th scope="col">Order Date </th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let order of orders;let i = index">
                  <td>{{i}}</td>
                  <td><b>{{order.id}}</b></td>
                  <td>{{order.createdat}}</td>
                  <td *ngIf="order.status === 'paid'">{{order.status}}</td>
                  <td *ngIf="order.status !=='paid'">{{order.status}} <span (click)="payOrder(order.id)"   class="btn btn--sm" style="float:right;">  Pay</span></td>
                  <td><a [routerLink]="['/order', order.id]"   class="btn btn--grey btn--sm">Details</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul class="pagination shop-list" *ngIf="noOfPages > 1">
            <li *ngFor="let e of [].constructor(noOfPages); let i = index"><span (click)="changePage(i+1)" style="cursor:pointer;">{{i+1}}</span></li>
          </ul>
        </div>
  </div>
</div>
</div>
</div>
