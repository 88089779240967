import { Component, OnDestroy, OnInit } from '@angular/core';
import {GlobalChangesService} from '../../_services/global-changes.service';
import { Subscription } from 'rxjs';
import { CartService } from 'app/_services/cart.service';
@Component({
  selector: 'app-dropdwn-minicart',
  templateUrl: './dropdwn-minicart.component.html',
  styleUrls: ['./dropdwn-minicart.component.css']
})
export class DropdwnMinicartComponent implements OnInit,OnDestroy {

  miniCartStatus = false;
  subscription!: Subscription;
  cartListSubscription!: Subscription;
  cart!: any;
  totalCartSubscription!: Subscription;
  totalCart!: number;
  constructor(
    private globalChangesService: GlobalChangesService,
    private cartService: CartService) {

   }

  ngOnInit(): void {
    this.subscription = this.globalChangesService.miniCartStatus.subscribe(status => this.miniCartStatus = status);
    this.cartListSubscription = this.globalChangesService.cartList.subscribe(cart => this.cart = cart);
    this.totalCartSubscription = this.globalChangesService.cartTotal.subscribe(totalCart => this.totalCart = totalCart);
  }

ngOnDestroy(){
  this.subscription.unsubscribe();
}

 changeMiniCartStatus(status:boolean){
   this.miniCartStatus = true;
  this.globalChangesService.setMiniCartStatus(status);
 }
 removeItemFromCart(event:Event, id: number){
   event.stopPropagation();
   this.cartService.removeItem(id);
 }

}
