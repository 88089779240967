import { Component, OnInit } from '@angular/core';
import { ArtistsService } from 'app/_services/artists.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.css']
})
export class ArtistsComponent implements OnInit {
  artists: any[] = [];
  noOfPages: number = 1;
  currentPage: number =1;
  constructor(
    private spinner: NgxSpinnerService,
    private artistsService: ArtistsService
  ) { }

  ngOnInit(): void {
    this.getArtists();
  }
  showSpecificSpinner(name: string) {
    console.log('show spinner' + name);
    this.spinner.show(name,{
      type: 'ball-clip-rotate',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#98570c',
      fullScreen: false
    });
  }

  hideSpecificSpinner(name: string) {
     this.spinner.hide(name);
  }
  getArtists(page?:number){
    this.artistsService.getArtists(page).pipe(first()).subscribe((result: any) => {
      console.log(result);
      // tslint:disable-next-line: no-non-null-assertion
      this.artists! = result.data;
      this.artists.forEach((artist:any) => {
        this.showSpecificSpinner(artist.id);
      });
      this.noOfPages = Math.ceil(result.meta.count / 12);
      // tslint:disable-next-line: no-non-null-assertion
      //this.artists! = result.included.filter((elem: any) => elem.type === 'artist');
      console.log(this.artists);
    });
  }
  changePage(page: number){
    this.currentPage = page;
    this.getArtists(page);
    window.scroll(0, 0);
    // console.log(page);
  }
}
