
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {CitiesService} from './cities.service';


@Injectable({
  providedIn: 'root'
})
export class CitiesUpdateService {
  private countriesSource = new BehaviorSubject([]);
  private regionsSource = new BehaviorSubject([]);
  private citiesSource = new BehaviorSubject([]);
  countries = this.countriesSource.asObservable();
  regions = this.regionsSource.asObservable();
  cities = this.citiesSource.asObservable();
  constructor(private citiesService: CitiesService) { }

  updateCountriesList(search: string){
    this.citiesService.getCountries(search).subscribe((response: any) => {
          console.log(response.data);
          this.countriesSource.next(response.data);
    });
  }
  updateRegionsList(countryId: number, search: string){
    this.citiesService.getRegions(countryId, search).subscribe((response: any) => {
      console.log(response.data);
      this.regionsSource.next(response.data);
    });
  }
  updateCitiesList(regionId: number, search: string){
    this.citiesService.getCities(regionId, search).subscribe((response: any) => {
      console.log(response.data);
      this.citiesSource.next(response.data);
    });
  }

 


}
