import { Component, OnInit, OnDestroy } from '@angular/core';
import {GlobalChangesService} from '../../_services/global-changes.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mobmenu',
  templateUrl: './mobmenu.component.html',
  styleUrls: ['./mobmenu.component.css']
})
export class MobmenuComponent implements OnInit, OnDestroy{
  mobileMenuStatus = false;
  subscription!: Subscription
  constructor(private globalChangesService: GlobalChangesService) { }

  ngOnInit(): void {
    this.subscription = this.globalChangesService.mobilMenuStatus.subscribe(status => this.mobileMenuStatus = status);
  }
  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  changeMobileMenuStatus(status:boolean){
    //alert(this.mobileMenuStatus);
    this.globalChangesService.setMobileMenuStatus(status);
  }

}
