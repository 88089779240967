<div class="page-content" style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="index.html">Home</a></li>
        <li><span>Checkout</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <h1 class="text-center">Checkout page</h1>
      <div class="row" *ngIf="isLoggedIn; else notLoggedIn">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h2>Shipping Address</h2>
              <div class="mt-2"></div>
                <div class="clearfix" *ngFor ="let address of addresses; index as i">
                  <input id="address-{{i}}" value="" name="address" type="radio" class="radio" [checked]="i==0" (click)="setShippinAddress(address.id)">
                  <label for="address-{{i}}">{{address.name}}, {{address.phone}},{{address.address1}}, {{address.address2}} {{getFullCity(address)}}</label>
                </div>
            </div>
          </div>
          <div class="mt-2"></div>
        </div>
        <div class="col-md-6 mt-2 mt-md-0">

          <div class="mt-2"></div>
          <div class="card">
            <div class="card-body">
              
              <h2>Payment Method</h2>

              <div class="clearfix">
                <img src="../../../assets/images/paypal-payment.png">
              </div>


            </div>
          </div>
          <div class="mt-2"></div>
          <div class="card">
 
          </div>
        </div>
      </div>
      <ng-template #notLoggedIn>
        <h2 style= "text-align:center; margin:80px; ">You must be logged in to place an order! <br>Please Login or <a [routerLink]="['/register']">Register</a></h2>

      </ng-template>
      <div class="mt-3"></div>
      <h2 class="custom-color">Order Summary</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="cart-table cart-table--sm pt-3 pt-md-0">
            <div class="cart-table-prd cart-table-prd--head py-1 d-none d-md-flex">
              <div class="cart-table-prd-image text-center">
                Image
              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">Name</div>
                <div class="cart-table-prd-qty">Qty</div>
                <div class="cart-table-prd-price">Price</div>
              </div>
            </div>
            <div class="cart-table-prd" *ngFor="let product of cart">
              <div class="cart-table-prd-image">
                <a [routerLink]="['/product', product.productid]" class="prd-img"><img class="" src="https://api.artourafrica.com/api/imgthumb/?size=80&id={{product.img}}" data-src="https://api.artourafrica.com/api/imgthumb/?size=80&id={{product.img}}" alt=""></a>
              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">
                  <h2 class="cart-table-prd-name"><a [routerLink]="['/product', product.productid]">{{product.title}}</a></h2>
                </div>
                <div class="cart-table-prd-qty">
                  <div class="qty qty-changer">
                    <input type="text" class="qty-input disabled" value="{{product.quantity}}" data-min="0" data-max="1000" disabled>
                  </div>
                </div>
                <div class="cart-table-prd-price-total">
                  ${{product.price * product.quantity}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-2 mt-md-0"></div>
        <div class="col-md-6 mt-2 mt-md-0">
          <div class="mt-2"></div>
          <div class="cart-total-sm">
            <span>Subtotal</span>
            <span class="card-total-price">$ {{totalCart}}</span>
          </div>
          <div class="clearfix mt-2">
            <button (click)="placeOrder()" type="button" class="btn btn--lg w-100" [disabled]="!isLoggedIn" >Pay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
