<div class="page-content" style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><span>Cart</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container" *ngIf="cart && cart.length > 0; else cartEmpty">
      <div class="page-title text-center">
        <h1>Shopping Cart</h1>
      </div>
      <div class="row">

        <div class="col-lg-8 col-xl-8">
          <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)">
            <p class="loading">Please Wait...</p>
          </ngx-spinner>
          <div class="cart-table" >
            <div class="cart-table-prd cart-table-prd--head py-1 d-none d-md-flex">
              <div class="cart-table-prd-image text-center">
                Image
              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">Name</div>
                <div class="cart-table-prd-qty">Qty</div>
                <div class="cart-table-prd-price">Price</div>
                <div class="cart-table-prd-action">&nbsp;</div>
              </div>
            </div>
            <div *ngFor="let product of cart" class="cart-table-prd">
              <div class="cart-table-prd-image">
                <a [routerLink]="['/product', product.productid]"  class="prd-img"><img class="fade-up lazyloaded" src="https://api.artourafrica.com/api/imgthumb/?size=80&id={{product.img}}" alt="{{product.title}}"></a>
              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">
                  <div class="cart-table-prd-price">
                    <div class="price-old">${{(product.price*1.15).toFixed(1)}}</div>
                    <div class="price-new">${{product.price}}</div>
                  </div>
                  <h2 class="cart-table-prd-name"><a [routerLink]="['/product', product.productid]" >{{product.title}}</a></h2>
                </div>
                <div class="cart-table-prd-qty">
                  <div class="qty qty-changer">
                    <button (click)="decreaseQty(product.productid)" class="decrease"></button>
                    <input type="text" class="qty-input" value="{{product.quantity}}" data-min="0" data-max="1000">
                    <button (click)="increaseQty(product.productid)" class="increase"></button>
                  </div>
                </div>
                <div class="cart-table-prd-price-total">
                  ${{product.price * product.quantity}}
                </div>
              </div>
              <div class="cart-table-prd-action">
                <span class="cart-table-prd-remove" data-tooltip="Remove Product"><i (click)="removeItemFromCart($event, product.productid)" class="icon-recycle"></i></span>
              </div>
            </div>

          </div>
          <div *ngIf="cart && cart.length > 0" class="text-center mt-1"><span (click)="emptyCart()" class="btn btn--grey">Clear All</span></div>
        </div>
        <div class="col-lg-4 col-xl-4 mt-3 mt-md-0">
          <div class="cart-promo-banner">
            <div class="cart-promo-banner-inside">
              <div class="txt1">Visit our</div>
              <div class="txt2">Virtual Museums!</div>
            </div>
          </div>
          <div class="card-total">
<!--             <div class="text-right">
              <button class="btn btn--grey"><span>UPDATE CART</span><i class="icon-refresh"></i></button>
            </div> -->
            <div class="row d-flex">
              <div class="col card-total-txt">Total</div>
              <div class="col-auto card-total-price text-right">$ {{totalCart}}</div>
            </div>
            <a *ngIf="cart && cart.length > 0" routerLink="/checkout"><button class="btn btn--full btn--lg"><span>Checkout</span></button></a>
            <div class="card-text-info text-right">
              <h5>Standard shipping</h5>
              <p><b>10 - 11 business days</b><br>1 item ships from the U.S. and will be delivered in 10 - 11 business days</p>
            </div>
          </div>
          <div class="mt-2"></div>
<!--           <div class="panel-group panel-group--style1 prd-block_accordion" id="productAccordion">
            <div class="panel">
              <div class="panel-heading active">
                <h4 class="panel-title">
                  <a data-toggle="collapse" data-parent="#productAccordion" href="#collapse1">Shipping options</a>
                  <span class="toggle-arrow"><span></span><span></span></span>
                </h4>
              </div>
              <div id="collapse1" class="panel-collapse collapse show">
                <div class="panel-body">
                  <label>Country:</label>
                  <div class="form-group select-wrapper select-wrapper-sm">
                    <select class="form-control form-control--sm">
                      <option value="United States">United States</option>
                      <option value="Canada">Canada</option>
                      <option value="China">China</option>
                      <option value="India">India</option>
                      <option value="Italy">Italy</option>
                      <option value="Mexico">Mexico</option>
                    </select>
                  </div>
                  <label>State:</label>
                  <div class="form-group select-wrapper select-wrapper-sm">
                    <select class="form-control form-control--sm">
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                    </select>
                  </div>
                  <label>Zip/Postal code:</label>
                  <div class="form-group">
                    <input type="text" class="form-control form-control--sm">
                  </div>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading active">
                <h4 class="panel-title">
                  <a data-toggle="collapse" data-parent="#productAccordion" href="#collapse3">Note for the seller</a>
                  <span class="toggle-arrow"><span></span><span></span></span>
                </h4>
              </div>
              <div id="collapse3" class="panel-collapse collapse show">
                <div class="panel-body">
                  <textarea class="form-control form-control--sm textarea--height-100" placeholder="Text here"></textarea>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <ng-template #cartEmpty>
      <div class="page404-bg" style="min-height: 226px;">
        <div class="page404-text">
          <div _ngcontent-rhv-c83="" class="minicart-empty js-minicart-empty">
            <div _ngcontent-rhv-c83="" class="minicart-empty-icon">
              <i _ngcontent-rhv-c83="" class="icon-shopping-bag"></i>
                </div></div>
          <div class="txt2">Your shopping cart is empty!</div>
        </div>
      </div>

    </ng-template>
    </div>
</div>
