<app-layout>
  <app-header></app-header>
  <div class="header-side-panel">
    <app-mobmenu></app-mobmenu>
    <app-dropdwn-account></app-dropdwn-account>
    <app-dropdwn-minicart></app-dropdwn-minicart>
  </div>
  <main>
    <router-outlet (activate)="onActivate($event)" ></router-outlet>
  </main>
  <app-footer></app-footer>
  <alert></alert>
</app-layout>
