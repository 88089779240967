import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {GlobalChangesService} from './_services/global-changes.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'art-tour';

  constructor(
    private router:Router,
    private globalChangesService:GlobalChangesService
  ){

  }

  public ngOnInit(): void {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log('navigation start');
          this.globalChangesService.setMobileMenuStatus(false);
          this.globalChangesService.setMiniCartStatus(false);
          this.globalChangesService.setMobileMenuStatus(false);

      }

      if (event instanceof NavigationEnd) {
          // Hide loading indicator
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }

  });
  }
  onActivate(event:Event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}
}
