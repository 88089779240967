import { Component, OnInit } from '@angular/core';
import {OrderService} from 'app/_services/order.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AddressesService } from 'app/_services/addresses.service';
import { ProductsService } from 'app/_services/products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'app/_services/alert.service';
import { CartService } from 'app/_services/cart.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  orderProducts: any[] = [];
  order: any = {};
  orderAddress: any = {};
  productsPrice: number = 0;
  orderId = '';
  address: any = {};
  constructor(
    private spinner: NgxSpinnerService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private addressesService: AddressesService,
    private productsService: ProductsService,
    private router: Router,
    private alertService: AlertService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.orderId = params.get('id') || '';
      console.log(this.orderId);
     // this.getOrder(this.orderId);
      this.getOrder(this.orderId);
    });
  }
  showSpecificSpinner(name: string) {
    console.log('show spinner' + name);
    this.spinner.show(name,{
      type: 'ball-clip-rotate',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#98570c',
      fullScreen: false
    });
  }

  hideSpecificSpinner(name: string) {
     this.spinner.hide(name);
  }
  getOrder(id: string){
    this.orderService.getOrder(id).pipe(first()).subscribe((result: any) => {
      this.order = result.data;
      console.log(this.order);
      this.orderAddress = result.included.find((item: any) => {
        return item.type = 'address';
      }).attributes;
      console.log(this.orderAddress);
      this.getAddress(this.orderAddress.id);
      this.getOrderItems(this.orderId);
  });
  }
  payOrder(orderId: string){
    this.orderService.payment(orderId).pipe(first()).subscribe((response:any)=>{
      if(response.success){
        console.log(response.link);
        this.router.ngOnDestroy();
        window.location.href = response.link;
        this.cartService.emptyCart();
      }else{
        console.log(response);
        this.alertService.error('Something Wrong! Please contact the Administrator');
      }
    })
  }
  getOrderItems(id: string){
    this.orderService.getOrderItems(id).pipe(first()).subscribe((result: any) => {
      console.log(result.included);
      let productsDetails = result.included.filter((item: any) => {
        return item.type  === 'product';
      });
      productsDetails = productsDetails.map((product: any) => {
        return product = product.attributes;
      });
      console.log(productsDetails);
      this.orderProducts = result.data;
      console.log(this.orderProducts);
      const included = result.included;
      this.orderProducts = this.orderProducts.map((product) => {
         const productDetails = productsDetails.find((item: any) => item.id === product.productid);
         this.showSpecificSpinner('product-' + product.productid);
         console.log(productDetails);
         product.title = productDetails.title;
         return product;
      });
      this.orderProducts.forEach((product: any) => {
        this.productsPrice = this.productsPrice + (product.price * product.quantity);

      });
      this.getProductsPictures();
  });
  }
  getAddress(id: number){
    this.addressesService.getAddress(id).pipe(first()).subscribe((result: any) => {
      const countryName = result.included.find((item: any) => item.type === 'country').attributes.name;
      const regionName = result.included.find((item: any) => item.type === 'region').attributes.name;
      const cityName = result.included.find((item: any) => item.type === 'city').attributes.name;
      this.orderAddress.countryName = countryName;
      this.orderAddress.regionName = regionName;
      this.orderAddress.cityName = cityName;
      console.log(this.orderAddress);
    });
  }
  getProductsPictures(){
    for (let product of this.orderProducts){

      this.productsService.getProduct(product.productid).pipe(first()).subscribe((result: any) => {
        let image = result.data.images[0];
        product.img = image;
      });

    }
    console.log('img updated');
    console.log(this.orderProducts);

  }
}
