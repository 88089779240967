<div *ngIf="product.title" class="page-content" style="min-height: 226px;">
  <ngx-spinner ></ngx-spinner>
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/museums">Museums</a></li>
        <li><span>{{product.title}}</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <div class="page-title text-center">
        <h1>{{product.title}}</h1>
      </div>

      <div class="row">
        <div class="col-md-12 aside aside--content">
          <div class="post-full">
            <div class="post-links">
              <div class="post-date"><i class="icon-calendar"></i>Ticket expires at    <span style="color:red;margin-left:10px;"> {{product.ticket.expiresat}}</span></div>
            </div>
            <div class="post-img movie-container" style="padding-bottom: 56.2%; position: relative;" (contextmenu)="rightClickMenu($event)">
              <div class="movie-inner-container">
              <ng-container *ngIf="mainImage==='video';else isImage">
                <app-video-player [playerOptions]="playerOptions" [videoSources]="videoSources" [moviePoster]="moviePoster"></app-video-player>
              </ng-container>
              <ng-template #isImage>
                <img src="{{currentImage}}" id="product-picture" class="elzoom" alt=""  (load)="hideSpecificSpinner('product-image')">
              </ng-template>
            </div>
            </div>
            <div class="product-previews-wrapper" *ngIf="images.length >1">
              <owl-carousel [options]="productGalleryOptions" [carouselClasses]="['owl-theme', 'sliding']">
                <div class="item" *ngFor="let video of privateVideos">
                  <ngx-spinner name="carousel-video-{{video.id}}" ></ngx-spinner>
                  <div class="card">
                    <!--
                       <div class='product-gallery-image-container' (click)="changeImage(image.url)">
                    -->
                    <div class='product-gallery-image-container' (click)="loadVideo(video)">
                    <img class="card-img-top product-gallery-image" [src]="video.url" style="cursor:pointer;" alt="Card image cap" (load)="hideSpecificSpinner('carousel-video-'+ video.id)">
                    <div class="overlay">
                      <span href="#" class="play-icon" title="Video Play">
                       <img src="../../assets/images/play-button.png">
                      </span>
                    </div>
                  </div>
                  </div>
                </div>

                <div class="item" *ngFor="let image of privateImages">
                  <ngx-spinner name="carousel-image-{{image.id}}" ></ngx-spinner>
                  <div class="card">
                    <!--
                       <div class='product-gallery-image-container' (click)="changeImage(image.url)">
                    -->
                    <div class='product-gallery-image-container' (click)="loadImage(image.url)">
                    <img class="card-img-top product-gallery-image" [src]="image.url" style="cursor:pointer;" alt="Card image cap" (load)="hideSpecificSpinner('carousel-image-'+ image.id)">
                  </div>
                  </div>
                </div>
              </owl-carousel>
            </div>
            <div class="post-text">
              {{product.description}}
            </div>

          </div>
          <div class="container" style="margin-top:80px;">
            <div class="title-wrap text-center">
              <h2 class="h1-style">Our Museums</h2>

            </div>
            <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding']" *ngIf="productsCarousel.length > 0">
              <div class="item" *ngFor="let product of productsCarousel; let i = index">
                <div class="card" style="width: 18rem;">
                  <ngx-spinner name="product-carousel-{{product.id}}" ></ngx-spinner>
                  <div class='image-container'>
                  <img class="card-img-top" [src]="productCarouselImages[i]" alt="Card image cap" (load)="hideSpecificSpinner('product-carousel-'+ product.id)">
                </div>
                  <div class="card-body">
                    <h5 class="card-title">{{product.title}}</h5>
                    <a [routerLink]="['/museum', product.id]" class="btn btn-primary">Details</a>
                  </div>
                </div>
              </div>
            </owl-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
