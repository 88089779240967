import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AddressesService } from 'app/_services/addresses.service';
import { AlertService } from 'app/_services/alert.service';
import { CitiesUpdateService } from 'app/_services/cities-update.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { UserService } from 'app/_services/user.service';
import { Subscription, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {
  @Input() address: any;
  @Input() fullCityName!: string ;
  @Input() fullRegionName!: string;
  @Input() fullCountryName!: string;
  @Input() visible: boolean | undefined;
  // tslint:disable-next-line: no-output-native
  @Output() close = new EventEmitter<any>();
  @Output() addressUpdated = new EventEmitter<any>();
  formLoaded = false;
  submitted = false;
  currentUser: any;
  addressForm: FormGroup = new FormGroup({});
  updateSuccess = false;
  countries: any = [];
  regions: any = [];
  cities: any = [];
  duplicateEmail = false;
  loading = false;
  currentUserSubscription!: Subscription;
  countriesSubscription!: Subscription;
  regionsSubscription!: Subscription;
  citiesSubscription!: Subscription;
  countryInput = new Subject<string>();
  regionInput = new Subject<string>();
  cityInput = new Subject<string>();
  countryPlaceholder = 'Select Country';
  regionPlaceholder = 'Select Region';
  cityPlaceholder = 'Select City';
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private citiesUpdateService: CitiesUpdateService,
    private router: Router,
    private alertService: AlertService,
    private addressesService: AddressesService,
    private globalChangesService: GlobalChangesService,
    ) {
    this.countryInput.subscribe((countryTerm) => {
      this.countriesUpdate(countryTerm);
  });
    this.regionInput.subscribe((regionTerm) => {
    this.regionsUpdate(regionTerm);
});
    this.cityInput.subscribe((cityTerm) => {
     this.citiesUpdate(cityTerm);
});
   }

  ngOnInit(): void {
    this.currentUserSubscription = this.globalChangesService.accountDetails.subscribe(accountDetails => this.currentUser = accountDetails);
    this.countriesSubscription = this.citiesUpdateService.countries.subscribe(countries => this.countries = countries);
    this.regionsSubscription = this.citiesUpdateService.regions.subscribe(regions => this.regions = regions);
    this.citiesSubscription = this.citiesUpdateService.cities.subscribe(cities => this.cities = cities);


    this.addressForm = this.fb.group(
      {
        name: ['', [Validators.required, Validators.minLength(3)]],
        phone: ['', [Validators.required]],
        address1: ['', [Validators.required, Validators.minLength(3)]],
        address2: [''],
        countryid: ['', [Validators.required]],
        regionid: ['', [Validators.required]],
        cityid: ['', [Validators.required]],
        postcode: ['', [Validators.required]]
      }
    );
    if (this.address){this.populateForm(); console.log('edit address');}
  }
  get f(): any{
    return this.addressForm.controls;
  }
  populateForm(){
    this.addressForm.setValue(
      {
        name: this.address.name,
        phone: this.address.phone,
        address1: this.address.address1,
        address2: this.address.address2,
        countryid: this.address.countryid,
        cityid: this.address.cityid,
        regionid: this.address.regionid,
        postcode: this.address.postcode
      }
    );
    this.countryPlaceholder = this.regionPlaceholder = this.cityPlaceholder = '';
    this.countries = [{id: this.address.countryid, name: this.fullCountryName}];
    this.regions = [{id: this.address.regionid, name: this.fullRegionName}];
    this.cities = [{id: this.address.cityid, name: this.fullCityName}];

 /*    if(this.fullCountryName){this.countryPlaceholder = this.fullCountryName};
    if(this.fullRegionName){this.regionPlaceholder = this.fullRegionName};
    if(this.fullCityName){this.cityPlaceholder = this.fullCityName}; */

  }
  countriesUpdate(searchString: string): void{
    console.log(searchString);
    this.citiesUpdateService.updateCountriesList(searchString);
    console.log(this.countries);
}

regionsUpdate( searchString: string): void{
  console.log(searchString);
  console.log('selected country');
  console.log(this.f.countryid.value);
  const countryId = this.f.countryid.value;
  this.citiesUpdateService.updateRegionsList(countryId, searchString);
  console.log(this.regions);
}

citiesUpdate( searchString: string): void{
  console.log(searchString);
  console.log('selected region');
  console.log(this.f.regionid.value);
  const regionId = this.f.regionid.value;
  this.citiesUpdateService.updateCitiesList(regionId, searchString);
  console.log(this.cities);
}
countryChanged(): void{
  console.log('COUNTRY CHANGED');
  if (!this.f.countryid.value){
    this.countryPlaceholder = 'Select Country';
  }else{
    this.countryPlaceholder = '';
  }

  console.log(this.f.countryid.value);
  this.f.regionid.reset();
  this.regionChanged();
  this.countries = [];
  // this.f.cityid.reset();
  }

  regionChanged(): void{
  if (!this.f.regionid.value){
    this.regionPlaceholder = 'Select Region';
  }else{
    this.regionPlaceholder = '';
  }
  console.log('REGION CHANGED');
  console.log(this.f.regionid.value);
  this.f.cityid.reset();
  this.cityChanged();
  }

  cityChanged(): void{
  if (!this.f.cityid.value){
    this.cityPlaceholder = 'Select City';
  }else{
    this.cityPlaceholder = '';
  }
  }
  cancel() {
    this.populateForm();
    this.close.emit(this.address.id);
  }
  submit(): void{
    console.log(typeof this.addressForm.value);
   // alert(this.addressForm.value);
    this.submitted = true;
    if (this.addressForm.invalid) {
       console.log('form invalid');
       console.log(this.addressForm.errors);
      // alert('Please fill all the required fields!');
       return ;
     } else {
       const formValues = this.addressForm.value;
       const formData: any = new FormData();
       console.log(formValues);
       for (const [key, value] of Object.entries(formValues)) {
         console.log(`${key}: ${value}`);
         if (value !== ''){
          formData.append(key, value);
         }
       }
       console.log(formData);
       if (this.address){
        formValues.id = this.address.id;
        this.addressesService.updateAddress(formValues)
        .pipe(first())
        .subscribe(
            (data: any) => {

                if (data.success){
                  this.updateSuccess = true;
                  this.alertService.success('Address Updated');
                  this.addressUpdated.emit();
                }else{
                  const saveErrors = data.saveErrors;
                  console.log(saveErrors);
                  this.alertService.error('Some error occured!');
                }

            },
            error => {
               // this.alertService.error(error);
                this.loading = false;
            });
       }else{
         console.log('ADD FORM');
         this.addressesService.addAddress(formData)
        .pipe(first())
        .subscribe(
            (data: any) => {

                if (data.success){
                  this.updateSuccess = true;
                  this.alertService.success('Address Successfully Added');
                  this.addressUpdated.emit();
                }else{
                  const saveErrors = data.saveErrors;
                  console.log(saveErrors);
                  this.alertService.error('Some error occured!');
                }

            },
            error => {
               // this.alertService.error(error);
                this.loading = false;
            });
       }

     }
  }

}
