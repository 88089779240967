import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ProductsService } from 'app/_services/products.service';
import { first } from 'rxjs/operators';
import { CartService } from 'app/_services/cart.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { ArtistsService } from 'app/_services/artists.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-artworks',
  templateUrl: './artworks.component.html',
  styleUrls: ['./artworks.component.css']
})
export class ArtworksComponent implements OnInit {
  filters!: any;
  filtersSelectedCount = 0;
  totalItems = 0;
  noOfPages = 0;
  appliedFilters: any = {artists: [], categories: [], isticket: 0};
  currentPage = 1;
  selectedCategories: number[] = [];
  selectedArtists: number[] = [];
  selectedPriceRanges: number[] = [];
  currentSelection = [];
  sortBy = '';
  products!: any[];
  categories: any[] = [];
  artists: any[] = [];
  filtersMobileShow = false;
  categoriesFilterExpand = true;
  pricesFilterExpand = true;
  artistsFilterExpand = true;
  loading = true;
  constructor(
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private productsService: ProductsService,
    private globalChangesService: GlobalChangesService,
    private cartService: CartService,
    private artistsService: ArtistsService) {
  }

  ngOnInit(): void {
    this.getCategories();
   // this.getFilters();
  }
  ngAfterViewInit(): void{
    //console.log('test');

  }
  showSpinner(){
    this.spinner.show(undefined,
      {
        type: 'ball-clip-rotate',
        size: 'medium',
        bdColor: 'gray',
        color: '#fff',
        fullScreen: false
      }
    );
  }
  hideSpinner(){
    this.spinner.hide();
  }

  showSpecificSpinner(name: string) {
    //console.log('show spinner' + name);
    this.spinner.show(name,{
      type: 'ball-clip-rotate',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#98570c',
      fullScreen: false
    });
  }

  hideSpecificSpinner(name: string) {
     this.spinner.hide(name);
  }
  getCategories(){
    this.productsService.getCategories().pipe(first()).subscribe((result: any) => {
      this.categories = result.data;
      this.getArtists();
    });
  }
  getArtists(){
    this.artistsService.getArtists().pipe(first()).subscribe((result: any) => {
      this.artists = result.data;
      this.getProducts(this.appliedFilters);
    });
  }
  getProducts(filters?: any){

    this.productsService.getProducts(filters).pipe(first()).subscribe((result: any) => {
      this.loading = true;
      this.products! = result.data;
      this.products.forEach((product:any) => {
          this.showSpecificSpinner(product.id);
      })
      this.totalItems = result.meta.count;
      this.noOfPages = Math.ceil(this.totalItems / 12);
      console.log(this.totalItems);
      console.log(this.products);
      this.getFilters();
      this.loading = false;
      this.products.forEach(product => {
        console.log(product.images);
      })
    });

  }
  imgLoaded(name:string){
   // this.hideSpecificSpinner(name);
  }
  changePage(page: number){
    this.currentPage = page;
    this.applyFilters();
    // console.log(page);
  }
  sortApply(event: Event){
    this.currentPage = 1;
    this.sortBy = (event.target as HTMLInputElement).value;
   // console.log(this.sortBy);
    this.applyFilters();
  }

  addToCart(product: any){
    //console.log('add to cart');
    this.cartService.addItem({productid: product.id, title: product.title, price: product.price, img: product.images[0].id}, 1);
  }
  getCategoryById(id: number){
    // tslint:disable-next-line: no-shadowed-variable
    const category = this.categories.find(category =>
      // tslint:disable-next-line: no-unused-expression
      category.id === id
    );
    return category.title;
  }
  getArtistById(id: number){
    // tslint:disable-next-line: no-shadowed-variable
    const artist = this.artists.find(category =>
      // tslint:disable-next-line: no-unused-expression
      category.id === id
    );
    return artist.name;
  }

  getFilters(){
    this.filters = {

      artists: this.artists,
      categories: this.categories
    };
  }

  toggleCategory(category: string){
     // this.categoriesFilterExpand = !this.categoriesFilterExpand;
      switch (category){
        case 'categories':
          this.categoriesFilterExpand = !this.categoriesFilterExpand;
          break;
          case 'artists':
            this.artistsFilterExpand = !this.artistsFilterExpand;
            break;
            case 'prices':
              this.pricesFilterExpand = !this.pricesFilterExpand;
              break;
              default:
                return;
      }
  }

  showFilters(){
      //console.log('Filters opened, adding class');
      this.filtersMobileShow = true;
      this.renderer.addClass(document.body, 'is-filters-opened');
      this.renderer.addClass(document.body, 'is-fixed');
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  closeFilters(){
    this.filtersMobileShow = false;
    this.renderer.removeClass(document.body, 'is-filters-opened');
    this.renderer.removeClass(document.body, 'is-fixed');
    this.renderer.removeStyle(document.body, 'overflow');
  }

  resetFilters(){
    this.closeFilters();
    this.selectedCategories = [];
    this.selectedArtists = [];
    const appliedFilters = {
      categories: this.selectedCategories,
      artists: this.selectedArtists,
      page: this.currentPage,
      sort: this.sortBy
    };
    this.appliedFilters = appliedFilters;
    //console.log(appliedFilters);
    this.filtersSelectedCount = this.selectedCategories.length + this.selectedArtists.length;
    this.closeFilters();
    this.getProducts(appliedFilters);
  }
  // tslint:disable-next-line: typedef
  applyFilters(){
    const appliedFilters = {
      categories: this.selectedCategories,
      artists: this.selectedArtists,
      page: this.currentPage,
      sort: this.sortBy,
      isticket: this.appliedFilters.isticket
    };
    this.appliedFilters = appliedFilters;
    this.filtersSelectedCount = this.selectedCategories.length + this.selectedArtists.length;
    if(this.filtersSelectedCount > 0){
      this.currentPage = 1;
      this.appliedFilters.page = 1;
    }
    //console.log(appliedFilters);
    this.closeFilters();
    this.getProducts(appliedFilters);
    window.scroll(0, 0);
  }
  addFilter(category: string, filter: number){
    //console.log(category + ' ' + filter);
    switch (category){
        case 'selectedCategories':
          if (this.selectedCategories.includes(filter)) {
            this.selectedCategories.splice(this.selectedCategories.indexOf(filter), 1);
          }else{
            this.selectedCategories.push(filter);
          }
          //console.log(this.selectedCategories);
          break;
          case 'selectedArtists':
            if (this.selectedArtists.includes(filter)) {
              this.selectedArtists.splice(this.selectedArtists.indexOf(filter), 1);
            }else{
              this.selectedArtists.push(filter);
            }
            //console.log(this.selectedArtists);
            break;
            case 'selectedPriceRanges':
              if (this.selectedPriceRanges.includes(filter)) {
                this.selectedPriceRanges.splice(this.selectedPriceRanges.indexOf(filter), 1);
              }else{
                this.selectedPriceRanges.push(filter);
              }
              //console.log(this.selectedPriceRanges);
              break;
        default:
          return;
      }
  }
  // tslint:disable-next-line: typedef
  removeFilter(id: number, type: string){
    switch (type){
      case 'category':
        if (this.selectedCategories.includes(id)) {
          this.selectedCategories.splice(this.selectedCategories.indexOf(id), 1);
        }
        break;
      case 'artist':
        if (this.selectedArtists.includes(id)) {
          this.selectedArtists.splice(this.selectedArtists.indexOf(id), 1);
        }
        break;
    }
  }
}
