import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  constructor(private http: HttpClient) {}

  getAddresses() { // get<Contact>
    return this.http.get(`${environment.apiUrl}item/address`);
  }
  getAddress(id:number){
    return this.http.get(`${environment.apiUrl}item/address?id=${id}`);
  }
  addAddress(address: any) { // get<Contact>
    return this.http.post(`${environment.apiUrl}item/address`, address);
  }
  updateAddress(address: any) { // get<Contact>
    return this.http.patch(`${environment.apiUrl}item/address`, address);
  }
  deleteAddress(id: string) {
    let httpParams = new HttpParams().set('id', id);
    let options = { params: httpParams };
    return this.http.request('delete', `${environment.apiUrl}item/address/?id=${id}`,{
      headers: new HttpHeaders()
        .set('Content-Type', 'text/plain')
    });
  }

}
