<div class="page-content" style="min-height: 472px;">
  <div class="holder mt-0 py-3 py-sm-5 py-md-10 bg-cover fade-up-fast lazyloaded" data-bgset="../../assets/images/contact-africa.jpg" style="background-image: url('../../assets/images/contact-africa.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9 col-xl-9">
          <div class="page-title-bg py-md-3">
            <h1 style="color:white;">CONTACT US</h1>
            <p style="color:white;">No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally</p>
            <p style="color:white;">Have a great question, comment or concern? Simply fill out the form below and click Submit. We will respond to all inquiries within 48 business hours.Check out our Frequently Asked Questions.</p>
          </div>
        </div>
      </div>
    </div>
  <picture style="display: none;"><source data-srcset="../../assets/images/contact-africa.jpg" sizes="1865px" srcset="../../assets/images/contact-africa.jpg"><img alt="" class="lazyautosizes lazyloaded" data-sizes="auto" data-parent-fit="cover" sizes="1865px"></picture></div>
  <div class="holder">
    <div class="container">
      <div class="title-wrap text-center">
        <h2 class="h1-style">Our Information</h2>
        <p class="h-sub maxW-825">Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally in which toil and pain</p>
      </div>
      <div class="text-icn-blocks-row">
        <div class="text-icn-block-hor">
          <div class="icn">
            <i class="icon-location"></i>
          </div>
          <div class="text">
            <h4>Address:</h4>
            <p>Level 3 178 Clarence St,
              Sydney NSW00 2005</p>
          </div>
        </div>
        <div class="text-icn-block-hor">
          <div class="icn">
            <i class="icon-phone"></i>
          </div>
          <div class="text">
            <h4>Phone:</h4>
            <p>+1 (919) 720 0162</p>
          </div>
        </div>
        <div class="text-icn-block-hor">
          <div class="icn">
            <i class="icon-info"></i>
          </div>
          <div class="text">
            <h4>Hours:</h4>
            <p>Hours: 7 Days a week<br>
              09:00am to 5:00pm</p>
          </div>
        </div>
        <div class="text-icn-block-hor">
          <div class="icn">
            <i class="icon-call-center"></i>
          </div>
          <div class="text">
            <h4>Support:</h4>
            <p>support@artourafrica.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <div class="row vert-margin">
        <div class="col-sm-9">
          <div class="title-wrap">
            <h2 class="h1-style">Get In Touch With Us</h2>
            <div>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally in which toil and pain</div>
          </div>
<!--           <form data-toggle="validator" class="contact-form" id="contactForm" novalidate="novalidate">
            <div class="form-confirm">
              <div class="success-confirm">
                Thanks! Your message has been sent. We will get back to you soon!
              </div>
              <div class="error-confirm">
                Oops! There was an error submitting form. Refresh and send again.
              </div>
            </div>
            <div class="form-group">
              <div class="row vert-margin-middle">
                <div class="col-lg">
                  <input type="text" name="name" class="form-control form-control--sm" placeholder="Name" required="">
                </div>
                <div class="col-lg">
                  <input type="text" name="lastName" class="form-control form-control--sm" placeholder="Last Name" required="">
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row vert-margin-middle">
                <div class="col-lg">
                  <input type="text" name="email" class="form-control form-control--sm" placeholder="Email" required="">
                </div>
                <div class="col-lg">
                  <input type="text" name="phone" class="form-control form-control--sm" placeholder="Phone" required="">
                </div>
              </div>
            </div>
            <div class="form-group">
              <textarea class="form-control form-control--sm textarea--height-200" name="message" placeholder="Message" required=""></textarea>
            </div>
            <button class="btn" type="submit">Send Message</button>
          </form> -->
        </div>
        <div class="col-sm-12" style="height:400px;">
          <div class="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2201.3258493677126!2d-74.01291322172017!3d40.70657451080482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sua!4v1492962272380"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder holder-subscribe-full holder-subscribe--compact">
  </div>
</div>
