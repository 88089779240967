import { Component, OnInit } from '@angular/core';
import { CartService } from 'app/_services/cart.service';
import { Subscription } from 'rxjs';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  cartListSubscription!: Subscription;
  cart!: any;
  totalCartSubscription!: Subscription;
  totalCart!: number;
  updateCart = false;
  constructor(
    private globalChangesService: GlobalChangesService,
    private cartService: CartService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.showSpinner();
    this.cartListSubscription = this.globalChangesService.cartList.subscribe(cart => this.cart = cart);
    this.totalCartSubscription = this.globalChangesService.cartTotal.subscribe(totalCart => this.totalCart = totalCart);
    console.log(JSON.stringify(this.cart));
    this.hideSpinner();
  }

  showSpinner(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        color: 'white',
        fullScreen: false
      }
    );
  }
  hideSpinner(){
    this.spinner.hide();
  }
  ngOnDestroy(){
    this.cartListSubscription.unsubscribe();
    this.totalCartSubscription.unsubscribe();
  }
  removeItemFromCart(event:Event, id: number){
    this.showSpinner();
    event.stopPropagation();
    this.cartService.removeItem(id);
    setTimeout(() => {  this.hideSpinner(); }, 1500);
  }
  updateQty(productId:number, qty: number){
    if(qty === 0){
      this.cartService.removeItem(productId);
    }else{
      this.cartService.updateQty(productId, qty);
    }
  }
  increaseQty(productId: number){
    console.log(productId);
    this.showSpinner();
    this.cartService.increaseQty(productId);
  setTimeout(() => {  this.hideSpinner(); }, 1500);
   // ;
  }
  decreaseQty(productId: number){
    this.showSpinner();
    this.cartService.decreaseQty(productId);
    setTimeout(() => {  this.hideSpinner(); }, 1500);
  }
 emptyCart(){
  this.showSpinner();
   this.cartService.emptyCart();
   setTimeout(() => {  this.hideSpinner(); }, 1500);
 }
}
