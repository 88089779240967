<div class="page-content" *ngIf="product">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/shop">Galleries</a></li>
        <li><span>{{product.title}}</span></li>
      </ul>
    </div>
  </div>
  <div>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)">
      <p class="loading">Please Wait...</p>
    </ngx-spinner>
  <div class="holder">
    <div class="container js-prd-gallery" id="prdGallery">
      <div class="row prd-block prd-block--prv-bottom prd-block--prv-double">
        <div class="col-md-5 col-lg-5 aside--sticky js-sticky-collision">
          <div class="aside-content">
            <!-- Product Gallery -->
            <div class="mb-2 js-prd-m-holder"></div>
            <div class="prd-block_main-image">
              <div class="prd-block_main-image-holder" id="prdMainImage">
                <div class="product-main-carousel js-product-main-carousel" data-zoom-position="inner">
                  <div data-value="Beige">
                    <ngx-spinner name="product-image" ></ngx-spinner>
                    <span class="prd-img">
                      <img src="{{currentImage}}" id="product-picture" class="elzoom" alt=""  (load)="hideSpecificSpinner('product-image')">
                    </span>
                  </div>
                </div>
                <div class="prd-block_label-sale-squared justify-content-center align-items-center"><span>Sale</span></div>
              </div>
              <div class="product-previews-wrapper" *ngIf="images.length >1">
                <owl-carousel [options]="productGalleryOptions" [carouselClasses]="['owl-theme', 'sliding']">
                  <div class="item" *ngFor="let image of images">
                    <ngx-spinner name="carousel-image-{{image.id}}" ></ngx-spinner>
                    <div class="card">
                      <div class='product-gallery-image-container' (click)="changeImage(image.url)">
                      <img class="card-img-top product-gallery-image" [src]="image.url" style="cursor:pointer;" alt="Card image cap" (load)="hideSpecificSpinner('carousel-image-'+ image.id)">
                    </div>
                    </div>
                  </div>
                </owl-carousel>
              </div>
            </div>
            <!-- /Product Gallery -->
          </div>
        </div>
        <div class="col-md-6 col-lg-6 mt-1 mt-md-0">
          <div class="prd-block_info prd-block_info--style1" data-prd-handle="/products/copy-of-suede-leather-mini-skirt">
            <div class="prd-block_info-top prd-block_info_item order-0 order-md-2">
              <div class="prd-block_price prd-block_price--style2">
                <div class="prd-block_price--actual">$ {{product.price}}</div>
                <div class="prd-block_price-old-wrap">
                  <span class="prd-block_price--old">$ {{(product.price*1.15).toFixed(1)}}</span>
                  <span class="prd-block_price--text">You Save: ${{(product.price * 1.15 - product.price).toFixed(1)}} ({{((product.price*1.15-product.price)/product.price*100).toFixed(1)}}%)</span>
                </div>
              </div>
            </div>
            <div class="prd-holder prd-block_info_item order-0 mt-15 mt-md-0">
              <div class="prd-block_title-wrap">
                <h1 class="prd-block_title">{{product.title}}</h1>
              </div>
            </div>
            <div class="prd-block_description prd-block_info_item ">
              <h3>Short description</h3>
              <p>{{product.shortdescription}}</p>
              <div class="mt-1"></div>
<!--               <div class="row vert-margin-less">
                <div class="col-sm">
                  <ul class="list-marker">
                    <li>100% Polyester</li>
                    <li>Lining:100% Viscose</li>
                  </ul>
                </div>
                <div class="col-sm">
                  <ul class="list-marker">
                    <li>Do not dry clean</li>
                    <li>Only non-chlorine</li>
                  </ul>
                </div>
              </div> -->
              <div class="mt-1"></div>
              <div class="row vert-margin-less">
                <div class="col-sm">

                </div>
              </div>
            </div>
            <div class="prd-block_info-box prd-block_info_item">
              <div class="two-column">
                <div class="row vert-margin-less">
                  <div class="col-sm">
                    <ul class="list-marker">
                      <li>Immersive experience!</li>
                      <li>Enjoy with your family!</li>
                      <li>Other message!</li>
                    </ul>
                  </div>
                </div>
                <div class="row vert-margin-less">
                  <div class="col-sm">
                    <ul class="list-marker">
                      <img class='img-24h' src="../../assets/images/24hours.png">
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="order-0 order-md-100">
                <div class="prd-block_actions prd-block_actions--wishlist">

                  <div class="btn-wrap">
                    <span (click)="addToCart(product)" class="btn btn--add-to-cart js-trigger-addtocart js-prd-addtocart" >Add ticket to Cart</span>
                  </div>
                </div>
            </div>
            <div class="prd-block_info_item">
              <ul class="prd-block_links list-unstyled">
                <li><i class="icon-delivery-1"></i><a [routerLink]="[]" data-fancybox class="modal-info-link" data-src="#deliveryInfo">Delivery and Return</a></li>
                <li><i class="icon-email-1"></i><a [routerLink]="[]" data-fancybox class="modal-info-link" data-src="#contactModal">Ask about this product</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder prd-block_links-wrap-bg d-none d-md-block">
    <div class="prd-block_links-wrap prd-block_info_item container mt-2 mt-md-5 py-1">
      <div class="prd-block_link"><span><i class="icon-call-center"></i>24/7 Support</span></div>
      <div class="prd-block_link">
        <span>Use promocode AFRICART to get 15% discount!</span>
      </div>
      <div class="prd-block_link"><span><i class="icon-delivery-truck"></i> Fast Shipping</span></div>
    </div>
  </div>
  <div class="holder mt-2 mt-md-5">
    <div class="container">
      <div class="panel-group panel-group--style1 prd-block_accordion" id="productAccordion">
        <div class="panel">
          <div class="panel-heading active">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#productAccordion" [routerLink]="[]">
                Description</a>
            </h4>
          </div>
          <div id="collapse2" class="panel-collapse collapse show">
            <div class="panel-body">
                {{product.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="holder">
    <div class="container">
      <div class="title-wrap text-center">
        <h2 class="h1-style">Our Galleries</h2>

      </div>
      <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding']" *ngIf="productsCarousel.length>0">
        <div class="item" *ngFor="let product of productsCarousel; let i = index">
          <div class="card" style="width: 18rem;">
            <ngx-spinner name="product-carousel-{{product.id}}" ></ngx-spinner>
            <div class='image-container'>
            <img class="card-img-top" [src]="productCarouselImages[i]" alt="Card image cap" (load)="hideSpecificSpinner('product-carousel-'+ product.id)">
          </div>
            <div class="card-body">
              <h5 class="card-title">{{product.title}}</h5>
              <a [routerLink]="['/gallery', product.id]" class="btn btn-primary">Details</a>
            </div>
          </div>
        </div>
      </owl-carousel>
    </div>
  </div>
</div>

