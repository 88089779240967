import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { UserService } from 'app/_services/user.service';
import { Subscription, Observable, Subject } from 'rxjs';
import { MustMatch } from '../../_helpers/must-match.validator';
import { CitiesUpdateService } from '../../_services/cities-update.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GlobalChangesService } from 'app/_services/global-changes.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup = new FormGroup({});
  submitted = false;
  registerSuccess = false;
  loading = false;
  countries = [];
  duplicateEmail = false;
  regions = [];
  cities = [];
  countriesSubscription!: Subscription;
  regionsSubscription!: Subscription;
  citiesSubscription!: Subscription;
  countryInput = new Subject<string>();
  regionInput = new Subject<string>();
  cityInput = new Subject<string>();
  countryPlaceholder = 'Select Country';
  regionPlaceholder = 'Select Region';
  cityPlaceholder = 'Select City';
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private citiesUpdateService: CitiesUpdateService,
    private globalChangesService: GlobalChangesService,
    private router: Router) {
      this.countryInput.subscribe((countryTerm) => {
          this.countriesUpdate(countryTerm);
      });
      this.regionInput.subscribe((regionTerm) => {
        this.regionsUpdate(regionTerm);
    });
      this.cityInput.subscribe((cityTerm) => {
         this.citiesUpdate(cityTerm);
  });
  }


  ngOnInit(): void {

    this.countriesSubscription = this.citiesUpdateService.countries.subscribe(countries => this.countries = countries);
    this.regionsSubscription = this.citiesUpdateService.regions.subscribe(regions => this.regions = regions);
    this.citiesSubscription = this.citiesUpdateService.cities.subscribe(cities => this.cities = cities);
    //this.miniAccountSubscription = this.globalChangesService.miniAccountStatus.subscribe(status => this.miniAccountStatus = status);

    this.registerForm = this.fb.group(
      {
        firstname: ['', [Validators.required, Validators.minLength(3)]],
        lastname: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordConfirm: ['', [Validators.required]],
        address1: ['', [Validators.required, Validators.minLength(3)]],
        address2: [''],
        countryid: ['', [Validators.required]],
        regionid: ['', [Validators.required]],
        cityid: ['', [Validators.required]],
        phone: ['', [Validators.required, Validators.minLength(6)]],
        postcode: ['', [Validators.required]],
        usertype: ['', [Validators.required]],
        vat: [''],
        company: [''],
        description: [''],
        shortdescription: [''],
        acceptTerms: [false, Validators.requiredTrue]
      },
      {
        validator: MustMatch('password', 'passwordConfirm')
      }
    );
    this.setUserType('user');
  }

  get f(): any{
    return this.registerForm.controls;
  }


  countriesUpdate(searchString: string): void{
      console.log(searchString);
      this.citiesUpdateService.updateCountriesList(searchString);
      console.log(this.countries);
  }
  regionsUpdate( searchString: string): void{
    console.log(searchString);
    console.log('selected country');
    console.log(this.f.countryid.value);
    const countryId = this.f.countryid.value;
    this.citiesUpdateService.updateRegionsList(countryId, searchString);
    console.log(this.regions);
}
  citiesUpdate( searchString: string): void{
    console.log(searchString);
    console.log('selected region');
    console.log(this.f.regionid.value);
    const regionId = this.f.regionid.value;
    this.citiesUpdateService.updateCitiesList(regionId, searchString);
    console.log(this.cities);
}

countryChanged(): void{
  console.log('COUNTRY CHANGED');
  if (!this.f.countryid.value){
    this.countryPlaceholder = 'Select Country';
  }else{
    this.countryPlaceholder = '';
  }
  console.log(this.f.countryid.value);
  this.f.regionid.reset();
  this.regionChanged();
  this.countries = [];
  // this.f.cityid.reset();
}
regionChanged(): void{
  if (!this.f.regionid.value){
    this.regionPlaceholder = 'Select Region';
  }else{
    this.regionPlaceholder = '';
  }
  console.log('REGION CHANGED');
  console.log(this.f.regionid.value);
  this.f.cityid.reset();
  this.cityChanged();
}

cityChanged():void{
  if (!this.f.cityid.value){
    this.cityPlaceholder = 'Select City';
  }else{
    this.cityPlaceholder = '';
  }

}

setUserType(type: string): void{

  switch (type){
    case 'user':
      this.f.usertype.setValue('user');
      break;
    case 'gallery':
      this.f.usertype.setValue('gallery');
      break;
    case 'museum':
        this.f.usertype.setValue('museum');
        break;
    default:
      this.f.usertype.setValue('user');
  }
}
    // code block
    changeMiniAccountStatus($event: Event, status: boolean){
      $event.stopPropagation();
      this.globalChangesService.setMiniAccountStatus(true);
    }
  submit(): void{
     console.log(typeof this.registerForm.value);
    // alert(this.registerForm.value);
     this.submitted = true;
     if (this.registerForm.invalid) {
        console.log('form invalid');
        console.log(this.registerForm.errors);
       // alert('Please fill all the required fields!');
        return ;
      } else {
        const formValues = this.registerForm.value;
        delete formValues.acceptTerms;
        delete formValues.passwordConfirm;
        const formData: any = new FormData();
        //console.log(typeof formValues);
        for (const [key, value] of Object.entries(formValues)) {
          console.log(`key: ${key}: ${value}`);
          formData.append(key, value);
        }
        console.log(formData);
        this.userService.register(formData)
            .pipe(first())
            .subscribe(
                (data: any) => {

                    if (data.success){
                      this.registerSuccess = true;
                    }else{
                      const saveErrors = data.saveErrors;
                      console.log(saveErrors);
                      const duplicateEmailError = saveErrors.find((elem: any) => elem.validator === 'unique');
                      if (duplicateEmailError){
                        this.duplicateEmail = true;
                      }
                      return;
                    }

                },
                error => {
                   // this.alertService.error(error);
                    this.loading = false;
                });
      }
  }

}
