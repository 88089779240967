<div class='page-content' style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><span>Galleries</span></li>
      </ul>
    </div>
  </div>
<div class='holder'>
<div class="container">
  <div class="page-title text-center">
    <h1>Our Galeries</h1>
  </div>
  <div class="post-prws-grid row">
    <div *ngFor="let product of products" class="col-sm-10 col-md-6">
      <div class="post-prw-simple">

        <div class="post-prw-img">
          <ngx-spinner name="{{product.id}}" ></ngx-spinner>
          <a [routerLink]="['/gallery', product.id]" class="image-hover-scale image-container" style="padding-bottom: 54.44%">
            <img src="https://api.artourafrica.com/api/imgthumb/?size=380&id={{product.images[0].id}}" onerror="this.src='../../assets/images/nophoto.jpg'" class="fade-up lazyloaded" alt="Post name" (load)="hideSpecificSpinner(product.id)">
          </a>
        </div>
 
        <h4 class="post-prw-title"><a [routerLink]="['/product', product.id]">{{product.title}}</a></h4>
          <div class="museums-grid-short-desc">{{(product.shortdescription.length>6)? (product.shortdescription | slice:0:300)+' ...more':(product.shortdescription) }}</div>
          <div class='row mt-4 mb-5'>
            <div class='col' style="text-align:left;">
              <div *ngIf="product.ticket; else noTicket">
                <div class="prd-block_countdown js-countdown-wrap prd-block_info_item countdown-init">
                  <div class="prd-block_ticket_expires">TICKET EXPIRES IN</div>
                  <div class="prd-block_price-old-wrap">
                    <div class="countdown js-countdown" >
                      <div
                        simpleCountdown
                        [dateTo]="product.ticketExpireAtTimestamp"
                        [endMessage]="'Access Expired'"
                        [language]="'en'"
                         [styles]="'
                           font-size: 16px;
                           text-align:center;
                           color:  #98570c;
                           background-color: #FFF;
                           padding: 3px 3px;
                           font-weight: bold;
                           min-width:10px;
                           margin:0 1px;
                           '"></div>

                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noTicket>
                <div class="prd-block_price prd-block_price--style2">
                  <div class="prd-block_price--actual">${{product.price}}</div>
                  <div class="prd-block_price-old-wrap">
                    <span class="prd-block_price--old">${{(product.price*1.15).toFixed(1)}}</span>
                    <span class="prd-block_price--text">24 HOURS ACCESS</span>
                  </div>
                </div>
              </ng-template>
            </div>
            <div *ngIf="!product.ticket;else btnNoTicket" class='col' style="text-align:right;"><a [routerLink]="['/museum', product.id]"  class='btn js-prd-addtocart'>Details</a></div>
            <ng-template #btnNoTicket><div class='col' style="text-align:right;"><a [routerLink]="['/museum-visit', product.id]"  class='btn btn-visit'>Private Tour</a>
              </div>
            </ng-template>
          </div>
        </div>
    </div>
  </div>
  <div class="pagination-wrap d-flex mt-4 justify-content-center">
    <ul class="pagination mt-0" *ngIf="noOfPages > 1">
      <li *ngFor="let e of [].constructor(noOfPages); let i = index"><span (click)="changePage(i+1)" style="cursor:pointer;">{{i+1}}</span></li>

    </ul>
  </div>
</div>
</div>
</div>
