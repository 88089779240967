<video
id="my-video"
class="video-js vjs-default-button vjs-big-play-centered vjs-16-9 vjs-fluid"
  controls
  playsinline
  preload="auto"
  data-setup="{}"
>


  <p class="vjs-no-js">
    To view this video please enable JavaScript, and consider upgrading to a
    web browser that
    <a href="https://videojs.com/html5-video-support/" target="_blank"
    >supports HTML5 video</a
    >
  </p>
</video>
