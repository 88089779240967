import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GlobalChangesService {
  private miniCartOpenedSource = new BehaviorSubject(false);
  private miniAccountOpenedSource = new BehaviorSubject(false);
  private mobilMenuOpenedSource = new BehaviorSubject(false);
  private mobileFiltersOpenedSource = new BehaviorSubject(false);
  private isLoggedInSource = new BehaviorSubject(false);
  private accountDetailsSource = new BehaviorSubject(false);
  private cartListSource = new BehaviorSubject([]);
  private cartTotalSource = new BehaviorSubject(0);

  miniCartStatus = this.miniCartOpenedSource.asObservable();
  miniAccountStatus = this.miniAccountOpenedSource.asObservable();
  mobilMenuStatus = this.mobilMenuOpenedSource.asObservable();
  mobileFiltersStatus = this.mobileFiltersOpenedSource.asObservable();
  isLoggedInStatus = this.isLoggedInSource.asObservable();
  accountDetails = this.accountDetailsSource.asObservable();
  cartList = this.cartListSource.asObservable();
  cartTotal = this.cartTotalSource.asObservable();

  constructor() { }

  setMiniCartStatus(status: boolean){
    this.miniCartOpenedSource.next(status);
  }
  setMiniAccountStatus(status: boolean){
    console.log('open:' + status);
    this.miniAccountOpenedSource.next(status);
  }
  setMobileMenuStatus(status: boolean){
    this.mobilMenuOpenedSource.next(status);
  }
  setMobileFiltersStatus(status: boolean){
    this.mobileFiltersOpenedSource.next(status);
  }
  setIsLoggedInStatus(status: boolean){
    this.isLoggedInSource.next(status);
  }
  setAccountDetails(user: any){
    this.accountDetailsSource.next(user);
  }
  setCart(products: any){
    this.cartListSource.next(products);
  }
  setTotalCart(amount: number){
    this.cartTotalSource.next(amount);
  }
}
