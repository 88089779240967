<div  *ngIf="message" class="footer-sticky">
<div class="payment-notification-wrap js-pn" data-visible-time="3000" data-hidden-time="3000" data-delay="500" data-from="Aberdeen,Bakersfield,Birmingham,Cambridge,Youngstown" data-products="[{&quot;productname&quot;:&quot;Leather Pegged Pants&quot;, &quot;productlink&quot;:&quot;product.html&quot;,&quot;productimage&quot;:&quot;images/skins/fashion/products/product-01-1.jpg&quot;},{&quot;productname&quot;:&quot;Black Fabric Backpack&quot;, &quot;productlink&quot;:&quot;product.html&quot;,&quot;productimage&quot;:&quot;images/skins/fashion/products/product-28-1.jpg&quot;},{&quot;productname&quot;:&quot;Combined Chunky Sneakers&quot;, &quot;productlink&quot;:&quot;product.html&quot;,&quot;productimage&quot;:&quot;images/skins/fashion/products/product-23-1.jpg&quot;}]" style="margin-bottom: 20px;">
  <div class="payment-notification payment-notification--squared" style="transform: translateY(0%) scale(1); opacity: 1;">
    <div class="payment-notification-inside">
      <div class="payment-notification-container" [ngClass]="message.cssClass">
        <div class="payment-notification-content-wrapper">
          <div class="payment-notification-content">
            <span>{{message.text}}</span>
            <!--
            <div class="payment-notification-bottom">
              <div class="payment-notification-when"><span class="js-pn-time">8</span> min ago</div>
              <div class="payment-notification-from">from <span class="js-pn-from">Youngstown</span></div>
            </div>
            -->
          </div>
        </div>
      </div>
      <div class="payment-notification-close" (click)=close()><i class="icon-close-bold"></i></div>
    </div>
  </div>
</div>
</div>
