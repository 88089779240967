import { Component, OnInit } from '@angular/core';
import { CartService } from 'app/_services/cart.service';
import { of, Subscription } from 'rxjs';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddressesService } from 'app/_services/addresses.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'app/_services/authentication.service';
import { OrderService } from 'app/_services/order.service';
import { AlertService } from 'app/_services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  cartListSubscription!: Subscription;
  cart!: any;
  totalCartSubscription!: Subscription;
  totalCart!: number;
  addresses: any;
  addressesIncluded: any;
  shippingAddressId!: number;
  isLoggedIn = false;


  constructor(
    private globalChangesService: GlobalChangesService,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private addressesService: AddressesService,
    private authenticationService: AuthenticationService,
    private orderService: OrderService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.showSpinner();
    this.getAddresses();
    this.cartListSubscription = this.globalChangesService.cartList.subscribe(cart => this.cart = cart);
    this.totalCartSubscription = this.globalChangesService.cartTotal.subscribe(totalCart => this.totalCart = totalCart);
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.hideSpinner();

  }
  showSpinner(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        color: 'white',
        fullScreen: false
      }
    );
  }
  hideSpinner(){
    this.spinner.hide();
  }
  getAddresses(){
    this.showSpinner();
    this.addressesService.getAddresses().pipe(first()).subscribe((response:any) =>{
     if(response.data){
       this.addresses = response.data;
       this.shippingAddressId = this.addresses[0].id;
       console.log(this.addresses);
       this.addressesIncluded = response.included;
       this.hideSpinner();
     }
    });
  }
  setShippinAddress(id: number){
    this.shippingAddressId = id;
    console.log('shipping address ' + id);
  }
  placeOrder(){
    console.log('place order');
    this.orderService.initiateOrder(this.shippingAddressId).pipe(first()).subscribe((response: any) => {
      if(response.success){
        const orderId = response.data.id;
        console.log('Order created, ID=' + orderId);
        this.updateOrder(orderId);
      }else{
        this.alertService.error('Something Wrong! Please contact the Administrator');
      }
    });
  }

  updateOrder(orderId: string){
    this.orderService.addItemsToOrder(orderId).pipe(first()).subscribe((response: any) => {
      if(response.success){
        this.alertService.success('The order was successfulyy placed');        
        //this.router.navigate(['/order-complete']);
        this.orderService.payment(orderId).pipe(first()).subscribe((response:any)=>{
          if(response.success){
            console.log(response.link);
            this.router.ngOnDestroy();
            window.location.href = response.link;
            this.cartService.emptyCart();
          }else{
            console.log(response);
            this.alertService.error('Something Wrong! Please contact the Administrator');
          }
        })
      }else{
        this.alertService.error('Something Wrong! Please contact the Administrator');
      }
    })
  }
  getFullCity(address: any){
    let country = this.addressesIncluded.find((elem:any) => {
       return elem.id === address.countryid;
    });
    let region = this.addressesIncluded.find((elem:any) => {
      return elem.id === address.regionid;
    });
    let city = this.addressesIncluded.find((elem:any) => {
     return elem.id === address.cityid;
   });
    return (`${city.attributes.name}, ${region.attributes.name}, ${country.attributes.name}`);
  }
}
