<header class="hdr-wrap">
  <div class="hdr-content hdr-content-sticky">
<div class="container">
  <div class="row">
    <div class="col-auto show-mobile">
<div class="menu-toggle"> <a [routerLink]="[]" class="mobilemenu-toggle"><i class="icon-menu"></i></a> </div>
    </div>
    <div class="col-auto hdr-logo">
      <a [routerLink]="[]" class="logo"><img srcset="./assets/images/logo.png 1x, ./assets/images/logo.png 2x" alt="Logo" type="image/webp"></a>
    </div>
    <div class="hdr-phone hide-mobile">
      <i class="icon-phone"></i><span>+1 (919) 720 0162</span>
    </div>
    <div class="hdr-nav hide-mobile nav-holder-s">
    </div>
    <div class="hdr-links-wrap col-auto ml-auto">
      <div class="hdr-inline-link">
<!-- <div class="search_container_desktop">
<div class="dropdn dropdn_search dropdn_fullwidth">
  <a [routerLink]="[]" class="dropdn-link  js-dropdn-link only-icon"><i class="icon-search"></i><span class="dropdn-link-txt">Search</span></a>
  <div class="dropdn-content" style="max-height: 939px; top: 71px;">
    <div class="container">
      <form method="get" action="#" class="search search-off-popular">
        <input name="search" type="text" class="search-input input-empty" placeholder="What are you looking for?">
        <button type="submit" class="search-button"><i class="icon-search"></i></button>
        <a [routerLink]="[]" class="search-close js-dropdn-close"><i class="icon-close-thin"></i></a>
      </form>
    </div>
  </div>
</div>
</div> -->
<div class="dropdn dropdn_account dropdn_fullheight">
<a [routerLink]="[]" class="dropdn-link js-dropdn-link js-dropdn-link only-icon" (click)="changeMiniCartStatus($event,true)"  data-panel="#dropdnAccount">
  <i class="icon-user"></i><span class="dropdn-link-txt">{{currentUser.firstname ? currentUser.firstname+' '+currentUser.lastname : "Account"}}</span>
</a>
</div>
        <div class="dropdn dropdn_fullheight minicart">
<a [routerLink]="[]" class="dropdn-link js-dropdn-link minicart-link only-icon" data-panel="#dropdnMinicart">
  <i class="icon-basket"></i>
  <span class="minicart-qty">{{cart.length}}</span>
  <span class="minicart-total hide-mobile">${{totalCart}}</span>
</a>
</div>
      </div>
    </div>
  </div>
</div>
</div>
  <div class="hdr hdr-style2">
      <div class="hdr-topline js-hdr-top">
          <div class="container">
              <div class="row">
                  <div class="col hdr-topline-left">
<div class="hdr-line-separate">
<ul class="social-list list-unstyled">
  <li>
    <a [routerLink]="[]" ><i class="icon-facebook"></i></a>
  </li>
  <li>
    <a [routerLink]="[]"><i class="icon-twitter"></i></a>
  </li>
  <li>
    <a [routerLink]="[]"><i class="icon-instagram"></i></a>
  </li>
  <li>
    <a [routerLink]="[]"><i class="icon-linkedin"></i></a>
  </li>
</ul>
</div>
                  </div>
                  <div class="col hdr-topline-right hide-mobile">
                      <div class="hdr-inline-link">


<div class="dropdn dropdn_account dropdn_fullheight">
<a [routerLink]="[]" (click) = "changeMiniAccountStatus($event, true)" class="dropdn-link js-dropdn-link" data-panel="#dropdnAccount"><i class="icon-user"></i><span class="dropdn-link-txt">{{currentUser.firstname ? currentUser.firstname+' '+currentUser.lastname : "Account"}}</span></a>
</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="hdr-content">
          <div class="container">
              <div class="row top-menu-row">
                  <div class="col-auto show-mobile">
<div class="menu-toggle"> <a [routerLink]="[]" class="mobilemenu-toggle" (click)="changeMobileMenuStatus($event, true)"><i class="icon-menu"></i></a> </div>
                  </div>
                  <div class="col-5 hdr-nav hide-mobile nav-holder">
<ul class="mmenu mmenu-js mmenu--withlabels">
<li class="mmenu-item--simple"><a routerLink="/home" routerLinkActive="active">Home</a></li>
<li class="mmenu-item--simple"><a routerLink="/aboutus" routerLinkActive="active">About Us</a>
<li class="mmenu-item--simple"><a routerLink="/artists" routerLinkActive="active">Artists</a></li>
<li class="mmenu-item--simple"><a routerLink="/museums" routerLinkActive="active">Museums</a>
<li class="mmenu-item--simple"><a routerLink="/galleries" routerLinkActive="active">Galleries</a></li>
<li class="mmenu-item--simple"><a routerLink="/shop" routerLinkActive="active">Artworks</a></li>
<li class="mmenu-item--simple"><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
</ul>
                  </div>
                  <div class=" col-lg-3 hdr-logo justify-content-md-left">
                      <a routerLink="/" class="logo"><img srcset="./assets/images/logo.png 1x, ./assets/images/logo.png 2x" alt="Logo" type="image/webp"></a>
                  </div>
                  <div class="col col-lg-5 hdr-links-wrap">
                      <div class="hdr-links">
                          <div class="hdr-phone">
                              <i class="icon-phone"></i><span>+1 (919) 720 0162</span>
                          </div>
                          <div class="hdr-inline-link">
<!-- <div class="search_container_desktop">
<div class="dropdn dropdn_search dropdn_fullwidth">
  <a [routerLink]="[]" class="dropdn-link  js-dropdn-link only-icon"><i class="icon-search"></i><span class="dropdn-link-txt">Search</span></a>
  <div class="dropdn-content" style="max-height: 939px; top: 91px;">
    <div class="container">
      <form method="get" action="#" class="search search-off-popular">
        <input name="search" type="text" class="search-input input-empty" placeholder="What are you looking for?">
        <button type="submit" class="search-button"><i class="icon-search"></i></button>
        <a [routerLink]="[]" class="search-close js-dropdn-close"><i class="icon-close-thin"></i></a>
      </form>
    </div>
  </div>
</div>
</div> -->
                              <div class="hdr_container_mobile show-mobile">
<div class="dropdn dropdn_account dropdn_fullheight">
<i class="icon-user"></i><span class="dropdn-link-txt" (click) = "changeMiniAccountStatus($event, true)">{{currentUser.firstname ? currentUser.firstname+' '+currentUser.lastname : "Account"}}</span>
</div>
                              </div>
                              <div class="dropdn dropdn_fullheight minicart" (click)="changeMiniCartStatus($event, true)">
<a [routerLink]="[]" class="dropdn-link js-dropdn-link minicart-link">
  <i class="icon-basket"></i>
  <span class="minicart-qty">{{cart.length}}</span>
  <span class="minicart-total hide-mobile">${{totalCart}}</span>
</a>
</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="hdr-promoline hdr-topline hdr-topline--dark">
          <div class="container">
              <div class="row">
                  <div class="col text-center">
                      <div class="custom-text js-custom-text-carousel slick-initialized slick-slider slick-vertical" data-slick="{&quot;speed&quot;: 1000, &quot;autoplaySpeed&quot;: 3000}">
<div class="slick-list" style="height: 42px;">
  <div class="slick-track" style="opacity: 1; height: 294px; transform: translate3d(0px, -42px, 0px);">
    <div class="custom-text-item slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 1570px;" tabindex="0">
      <i class="icon-fox"></i> Use promocode <span>AFR2021</span> to get 10% discount!
    </div>
    <div class="custom-text-item slick-slide" data-slick-index="1" aria-hidden="true" style="width: 1570px;" tabindex="-1"><i class="icon-air-freight"></i> <span>WorldWide</span> Shipping<span></span>
      </div>
        <div class="custom-text-item slick-slide slick-cloned" data-slick-index="4" aria-hidden="true" style="width: 1570px;" tabindex="-1">
          <i class="icon-air-freight"></i> <span>Shipping</span> Worldwide 
        </div>

      </div>
    </div>


</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>
