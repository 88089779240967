import { Component, OnInit, OnDestroy } from '@angular/core';
import {GlobalChangesService} from '../../_services/global-changes.service';
import { Subscription } from 'rxjs';
import {first} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { AuthenticationService } from 'app/_services/authentication.service';
import {User} from '../../_models/user';
import { AlertService } from 'app/_services/alert.service';
@Component({
  selector: 'app-dropdwn-account',
  templateUrl: './dropdwn-account.component.html',
  styleUrls: ['./dropdwn-account.component.css']
})

export class DropdwnAccountComponent implements OnInit, OnDestroy {
  miniAccountStatus = false;
  subscription!: Subscription;
  miniAccountSubscription!: Subscription;
  currentUserSubscription!: Subscription;
  loading = false;
  loginForm!: FormGroup;
  returnUrl!: string;
  isLoggedIn = false;
  submitted = false;
  currentUser!: any;
  loginError = false;
  constructor(
    private globalChangesService: GlobalChangesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
    ) {
      if(this.authenticationService.currentUserValue){
        this.isLoggedIn = true;
      }

    }

  ngOnInit(): void {
    this.miniAccountSubscription = this.globalChangesService.miniAccountStatus.subscribe(status => this.miniAccountStatus = status);
    this.currentUserSubscription = this.globalChangesService.accountDetails.subscribe(currentUser => this.currentUser = currentUser);

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    })
  }

  get f(){return this.loginForm.controls};

  ngOnDestroy(){
    this.miniAccountSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }
  changeMiniAccountStatus(status:boolean){
    this.globalChangesService.setMiniAccountStatus(status);
   }

   onSubmit(){
     this.submitted = true;

     if(this.loginForm.invalid){
       return;
     }
     this.loading = true;
     this.authenticationService.login(this.f.username.value, this.f.password.value)
                  .pipe(first())
                  .subscribe(
                    data => {
                      if (!data) {
                        this.loading = false;
                        return;
                      }
                      if(data.error){
                        console.log('eroare autentificare');
                        this.loading = false;
                        this.loginError = true;
                        return;
                      }
                      console.log(data);
                      console.log('User Logged In');
                      this.loginError = false;
                      this.loading = false;
                      this.isLoggedIn = true;
                      this.authenticationService.getUserDetails().pipe(first()).subscribe((result: any) => {
                        if (result) {
                          //console.log( result);
                        }
                      });
                      this.globalChangesService.setMiniAccountStatus(false);
                      this.alertService.success('You are now logged in');
                    },
                    error => {
                      console.log(error);
                      this.loading = false;
                    }
                  );
   }
   logout(){
    this.authenticationService.logout();
   }

}
