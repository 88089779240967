<div class='page-content' style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><span>Artists</span></li>
      </ul>
    </div>
  </div>
<div class='holder'>
<div class="container">
  <div class="page-title text-center">
    <h1>Our Artists</h1>
  </div>
  <div class="post-prws-grid row">
    <div *ngFor="let artist of artists" class="col-sm-9 col-md-4">
      <div class="post-prw-simple">

        <div class="post-prw-img">
          <ngx-spinner name="{{artist.id}}" ></ngx-spinner>
          <a [routerLink]="['/artist', artist.id]" class="image-hover-scale image-container" style="padding-bottom: 54.44%">
            <img src="https://api.artourafrica.com/api/imgthumb/?size=380&id={{artist.picture.id}}" onerror="this.src='../../assets/images/nophoto.jpg'" class="fade-up lazyloaded" alt="Post name" (load)="hideSpecificSpinner(artist.id)">
          </a>
        </div>
<!--         <div class="post-prw-links">
          <div class="post-prw-date">Las Vegas, USA</div>
          <div class="post-prw-author">international Artist</div>
        </div> -->
        <h4 class="post-prw-title"><a [routerLink]="['/artist', artist.id]">{{artist.name}}</a></h4>
      </div>
    </div>
  </div>
  <div class="pagination-wrap d-flex mt-4 justify-content-center">
    <ul class="pagination mt-0" *ngIf="noOfPages > 1">
      <li *ngFor="let e of [].constructor(noOfPages); let i = index"><span (click)="changePage(i+1)" style="cursor:pointer;">{{i+1}}</span></li>

    </ul>
  </div>
</div>
</div>
</div>
