import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { GlobalChangesService } from './global-changes.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private globalChangesService: GlobalChangesService,
    private alertService: AlertService) { }



  addItem (product: any, qty: number) {
    {
      let products = [];
      if (localStorage.getItem('products')){
          products = JSON.parse(localStorage.getItem('products') || '{}');
          console.log(products);

      }
      if (products.find((cartItem: any) => {
        return cartItem.productid === product.productid;
      })){
        console.log('product exists');
        this.alertService.error('Product already exists in cart');
      } else {
        products.push({productid : product.productid, title: product.title, price: product.price, img: product.img, quantity: qty});
        localStorage.setItem('products', JSON.stringify(products));
        this.updateTotalCart();
        this.globalChangesService.setCart(products);
        console.log('product added');
        this.alertService.success('Product added to cart');
      }

  }
  }
  removeItem(productId: number){
    const storageProducts = JSON.parse(localStorage.getItem('products') || '{}');

    const products = storageProducts.filter((product: any) => product.productid !== productId );
    localStorage.setItem('products', JSON.stringify(products));
    this.updateTotalCart();
    this.globalChangesService.setCart(products);
  }

  updateQty(productId: number, newQty: number){
    const storageProducts = JSON.parse(localStorage.getItem('products') || '{}');
    const products = storageProducts.map((product: any) => {
      if (product.productid === productId){
        console.log(product);
        product.quantity = newQty;
      }
    })
    localStorage.setItem('products', JSON.stringify(products));
    this.updateTotalCart();
    this.globalChangesService.setCart(products);

  }
  increaseQty(productId: number){
    console.log('increaseqty' + productId);
    const storageProducts = JSON.parse(localStorage.getItem('products') || '[]');
    let productIndex = storageProducts.findIndex(((item: any) => item.productid === productId));
    storageProducts[productIndex].quantity = storageProducts[productIndex].quantity + 1;
    localStorage.setItem('products', JSON.stringify(storageProducts));
    this.updateTotalCart();
    this.globalChangesService.setCart(storageProducts);

  }
  decreaseQty(productId: number){
    const storageProducts = JSON.parse(localStorage.getItem('products') || '[]');
    let productIndex = storageProducts.findIndex(((item: any) => item.productid === productId));
    storageProducts[productIndex].quantity = storageProducts[productIndex].quantity - 1;
    localStorage.setItem('products', JSON.stringify(storageProducts));
    this.updateTotalCart();
    this.globalChangesService.setCart(storageProducts);

  }

  emptyCart () {
    localStorage.removeItem('products');
    this.globalChangesService.setCart([]);
    this.updateTotalCart();
  }
  
  updateTotalCart(){
    let totalCart = 0;

    const storageProducts = JSON.parse(localStorage.getItem('products') || '[]');
    console.log(storageProducts);
    console.log(storageProducts.length);

    for (let i = 0; i < storageProducts.length; i++){
      totalCart = totalCart + storageProducts[i].price * storageProducts[i].quantity;
    }

    localStorage.setItem('totalCart', JSON.stringify(totalCart));
    this.globalChangesService.setTotalCart(totalCart);
  }
}
