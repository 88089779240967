<div class="page-content" style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="index.html">Home</a></li>
        <li><span>My account</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <div class="row">
        <div class="col-md-3 aside aside--left">
          <div class="list-group">
            <a routerLink="/profile" class="list-group-item active">Account Details</a>
            <a routerLink="/addresses" class="list-group-item ">My Addresses</a>
            <a routerLink="/orders" class="list-group-item">My Order History</a>
          </div>
        </div>
        <div class="col-md-9 aside">
          <div class="form-wrapper">
          <form [formGroup]="profileForm" (ngSubmit)="submit()">
            <div class="row register-title"><div class="col-sm-12">   <h2>Account Details</h2></div></div>
            <div class='row'><div class="col-sm-12" style="text-align:right;"><span (click)="enableForm()" id='editFormButton' class='btn btn--sm'>Edit Profile</span></div></div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <input formControlName="firstname" id= "firstname" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.firstname.invalid }" placeholder="First name">
                  <div *ngIf="f.firstname.invalid && submitted">
                    <div *ngIf="f.firstname.errors?.required" class="invalid-feedback">First Name is required.</div>
                    <div *ngIf="f.firstname.errors?.minlength" class="invalid-feedback">First Name must be at least 3 characters long.</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input formControlName="lastname" id= "lastname" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.lastname.invalid }" placeholder="Last name">
                  <div *ngIf="f.lastname.invalid && submitted">
                    <div *ngIf="f.lastname.errors?.required" class="invalid-feedback">Last Name is required.</div>
                    <div *ngIf="f.lastname.errors?.minlength" class="invalid-feedback">Last Name  must be at least 3 characters long.</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input formControlName="email" id= "email" type="text" class="form-control" [ngClass]="{'is-invalid': (submitted && f.email.invalid) || duplicateEmail}" placeholder="E-mail">
                  <div *ngIf="f.email.invalid && submitted">
                    <div *ngIf="f.email.errors?.required" class="invalid-feedback">Email is required.</div>
                    <div *ngIf="f.email.errors?.email" class="invalid-feedback">Not a valid email address.</div>
                  </div>
                  <div *ngIf="duplicateEmail">
                    <div class="invalid-feedback">This email address is already registered</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input formControlName="phone" id= "phone" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.phone.invalid }" placeholder="Phone">
                  <div *ngIf="f.phone.invalid && submitted">
                    <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Phone is required.</div>
                  </div>
                </div>
              </div>
              </div>
              <div class="row register-title"><div class="col-sm-12">       <h2>Address</h2></div></div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <input formControlName="address1" id= "address1" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.address1.invalid }" placeholder="Address">
                  <div *ngIf="f.address1.invalid && submitted">
                    <div *ngIf="f.address1.errors?.required" class="invalid-feedback">Address is required.</div>
                    <div *ngIf="f.address1.errors?.minlength" class="invalid-feedback">Address must be at least 6 characters long.</div>
                  </div>
                </div>
                <div class="form-group">
                  <input formControlName="address2" id= "address2" type="text" class="form-control" placeholder="Address 2">
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group select-wrapper select-wrapper-sm">
                      <ng-select class="form-control custom-ng " [ngClass]="{'is-invalid': submitted && f.countryid.invalid }"
                      [items]="countries"
                      placeholder={{countryPlaceholder}}
                      (clear) = "countryChanged()"
                      (change) = "countryChanged()"
                      typeToSearchText =" Type to search.."
                      [minTermLength] = 3
                      [typeahead] = countryInput
                      [clearSearchOnAdd] = true
                      [closeOnSelect] = true
                      [searchable]="true"
                      bindLabel="name"
                      bindValue="id"
                      loadingText="Loading..."
                      formControlName="countryid">
                      <ng-option [value]=currentUser.country.id>{{currentUser.country.id}}</ng-option>
                  </ng-select>
                  <div *ngIf="f.countryid.invalid && submitted">
                    <div  *ngIf="f.countryid.errors?.required" class="invalid-feedback">Country is required.</div>
                  </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group select-wrapper select-wrapper-sm" >
                      <ng-select class="form-control custom-ng" [ngClass]="{'is-invalid': submitted && f.regionid.invalid }"
                      [items]="regions"
                      placeholder={{regionPlaceholder}}
                      (clear) = "regionChanged()"
                      (change) = "regionChanged()"
                      typeToSearchText =" Type to search.."
                      [minTermLength] = 3
                      [typeahead] = regionInput
                      [searchable]="true"
                      bindLabel="name"
                      bindValue="id"
                      loadingText="Loading..."
                      formControlName="regionid">
                  </ng-select>
                  <div *ngIf="f.regionid.invalid && submitted">
                    <div  *ngIf="f.regionid.errors?.required" class="invalid-feedback">Region is required.</div>
                  </div>
                    </div>
                  </div>
                  </div>
                  <div class='row'>
                    <div class="col-sm-6">
                      <div class="form-group select-wrapper select-wrapper-sm">
                        <ng-select class="form-control custom-ng" [ngClass]="{'is-invalid': submitted && f.cityid.invalid }"
                        [items]="cities"
                        placeholder={{cityPlaceholder}}
                        (clear) = "cityChanged()"
                        (change) = "cityChanged()"
                        typeToSearchText =" Type to search.."
                        [minTermLength] = 3
                        [typeahead] = cityInput
                        [searchable]="true"
                        bindLabel="name"
                        bindValue="id"
                        loadingText="Loading..."
                        formControlName="cityid">
                    </ng-select>
                    <div *ngIf="f.cityid.invalid && submitted">
                      <div  *ngIf="f.cityid.errors?.required" class="invalid-feedback">City is required.</div>
                    </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input formControlName="postcode" id= "postcode" type="text" class="form-control" placeholder="ZipCode">
                  </div>
                </div>
              </div>
              </div>
          </div>
          <div *ngIf="f.usertype.value !='user'">
          <div class="row register-title"><div class="col-sm-12"> <h2>Company Details</h2></div></div>
          <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="company" placeholder="Company">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="vat" placeholder="VatNo">
                  </div>
                </div>
            </div>
          </div>
            <div class="text-center">
              <button class="btn" (click)="submit()" type="submit">Update Account</button>
            </div>
          </form>
          </div>
    </div>
  </div>
</div>
