import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'app/_services/products.service';
import { first } from 'rxjs/operators';
import { CartService } from 'app/_services/cart.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArtistsService } from 'app/_services/artists.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {
  artist: any;
  artistId: string ='';
  environment: any;
  productCarouselImages: any[] = [];
  productsCarousel:any[] =[];
  images: any[] = [];
  slideOptions = {
    nav: false,
    dots: false,
    loop: true,
    margin: 100,
    responsiveClass: true,
    // responsive object contains responsive options.
    responsive: {
      0: {
        items: 2,
        dots: false
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };
  constructor(
    private productsService: ProductsService,
    private globalChangesService: GlobalChangesService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private artistsService: ArtistsService
  ) { }

  ngOnInit(): void {
    this.environment = environment;
    this.route.paramMap.subscribe((params : ParamMap) => {
      this.artistId = params.get('id') || '';
      console.log('Artist' + this.artistId);
      this.getArtist(this.artistId);
    });
  }
  showSpecificSpinner(name: string) {
    console.log('show spinner' + name);
    this.spinner.show(name,{
      type: 'ball-clip-rotate',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#98570c',
      fullScreen: false
    });
  }

  hideSpecificSpinner(name: string) {
     this.spinner.hide(name);
  }

  getArtist(id: string){
    if(this.artistId !== ''){
      this.showSpecificSpinner('artist-image');
      console.log('get artist no ' + id);

      this.artistsService.getArtist(id).pipe(first()).subscribe((result: any) => {
        this.artist = result.data;
        console.log(this.artist);
        this.getProductsFromArtist(this.artist.id);
});
    }
  }
  getProductsFromArtist(artistId:number){
    let filters = {
      artists:[artistId]
    }
    this.productsService.getProducts(filters).pipe(first()).subscribe((result: any) => {
      this.productsCarousel = result.data;
      this.productsCarousel = this.productsCarousel.sort(() => 0.5 - Math.random());
      this.productsCarousel  = this.productsCarousel.slice(0, 6);
      console.log(this.productsCarousel);
      this.productsCarousel.forEach((product) => {
        this.showSpecificSpinner('artist-carousel-' + product.id);
        this.productCarouselImages.push(`https://api.artourafrica.com/api/imgthumb/?size=300&id=${product.images[0].id}`);
      });
      console.log(this.productCarouselImages);
    })
}
}
