<div class="page-content" style="min-height: 226px;">
    <div class="holder breadcrumbs-wrap mt-0">
      <div class="container">
        <ul class="breadcrumbs">
          <li><a href="index.html">Home</a></li>
          <li><span>PaymentFailed</span></li>
        </ul>
      </div>
    </div>
    <div class="holder">
      <div class="container">
        <h1 class="text-center">Payment Failed!</h1>
<ng-container>
    <div class="container">
    <div class="thanks-bg" style="min-height: 256px;">

    </div>
    <div class="thanks-info text-center">
      <a class="btn" routerLink="/home">Continue Shopping</a>
    </div>
  </div>
  </ng-container>
  </div>
  </div>
  </div>
