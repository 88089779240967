<div class="page-content" style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="index.html">Home</a></li>
        <li><span>Cart</span></li>
      </ul>
    </div>
  </div>
  <div class="holder mt-0">
    <div class="container">
      <div class="page404-bg" style="min-height: 226px;">
        <div class="page404-text">
          <div class="txt1"><img src="../../../assets/images/pages/order-completed.png" alt=""></div>
          <div class="txt2">Your order is complete!</div>
          <div><p>Your will receive a confirmation email with order details!</p></div>
          <br>
          <div class="btn-wrap">
            <a routerLink='/home' class="btn btn--add-to-cart js-trigger-addtocart js-prd-addtocart" >Continue shopping</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
