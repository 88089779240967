import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  register(user: any) {
    return this.http.post(`${environment.apiUrl}item/user`, user);
  }
  getUser() {
    return this.http.get(`${environment.apiUrl}item/user`);
  }
  update(user: any) {
    return this.http.patch(`${environment.apiUrl}item/user`, user);
  }


}
