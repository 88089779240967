<div class="page-content" style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a href="index.html">Home</a></li>
        <li><span>Order Details</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container">
      <h1 class="text-center">Order summary</h1>
      <div class="mt-3"></div>
      <h2 class="custom-color">Order Summary</h2>
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h3>Order Details</h3>
              <div class="card-text-info mt-2">
                <p><b>Created at: </b>{{order.createdat}}</p>
                <p><b>Status: </b>{{order.status}}</p>
                <p><b>Shipping Address:</b> </p>
                <p><b>{{orderAddress.name}}</b></p>
                <p>{{orderAddress.address1}}</p>
                <p>{{orderAddress.address2}}</p>
                <p>{{orderAddress.cityName}}</p>
                <p>{{orderAddress.regionName}}</p>
                <p>{{orderAddress.countryName}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mt-2 mt-md-0">
          <div class="cart-table cart-table--sm pt-3 pt-md-0">
            <div class="cart-table-prd cart-table-prd--head py-1 d-none d-md-flex">
              <div class="cart-table-prd-image text-center">
                Image
              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">Name</div>
                <div class="cart-table-prd-qty">Qty</div>
                <div class="cart-table-prd-price">Price</div>
              </div>
            </div>
            <div class="cart-table-prd" *ngFor="let product of orderProducts">
              <div class="cart-table-prd-image">

                <a [routerLink]="['/product', product.productid]" class="prd-img" ><ngx-spinner name="product-{{product.productid}}" ></ngx-spinner>
                  <img class="fade-up ls-is-cached lazyloaded" src="https://api.artourafrica.com/api/imgthumb/?size=80&id={{product.img.id}}" alt="" *ngIf="product.img" (load)="hideSpecificSpinner('product-'+product.productid)">
                </a>
              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">
                  <h2 class="cart-table-prd-name"><a [routerLink]="['/product', product.productid]" >{{product.title}}</a></h2>
                </div>
                <div class="cart-table-prd-qty">
                  <div class="qty qty-changer">
                    <input type="text" class="qty-input disabled" value="{{product.quantity}}" data-min="0" data-max="1000" disabled>
                  </div>
                </div>
                <div class="cart-table-prd-price-total">
                  ${{product.price}}
                </div>
              </div>
            </div>
            <div class="cart-table-prd">
              <div class="cart-table-prd-image">

              </div>
              <div class="cart-table-prd-content-wrap">
                <div class="cart-table-prd-info">
                </div>
                  <div class="cart-table-prd-price-total">
                    <h3>Subtotal</h3>
                    <h3>Shipping</h3>
                    <h2>Total Price</h2>
                  </div>
                <div class="cart-table-prd-price-total">
                  <h3>${{productsPrice}}</h3>
                  <h3>$0</h3>
                  <h2>${{productsPrice}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-md-4 text-right">
          <span  routerLink="/orders" class="btn btn--md btn--grey" style="float:left;"><span>Back to order list</span></span>
          <span  *ngIf="order.status!=='paid'" (click)="payOrder(order.id)" class="btn btn--md "><span>Pay</span></span>
        </div>

      </div>
    </div>
  </div>
</div>
