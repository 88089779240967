import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

initiateOrder(addressid: number){
  const formData: any = new FormData();
  formData.append('status', 'payment pending');
  formData.append('addressid', addressid);
  return this.http.post<any>(`${environment.apiUrl}item/order`, formData);
}

  addItemsToOrder (addressid: string) {
    let products = [];
    if (localStorage.getItem('products')){
     products = JSON.parse(localStorage.getItem('products') || '{}');
     products.map((product: any) => {
        product.orderid = addressid;
        delete product.img;
        delete product.title;
      });
     console.log(products);
    }
    return this.http.post<any>(`${environment.apiUrl}item/orderitem/?multiple=1`, JSON.stringify(products));

  }
  getOrders(page?: number){
    return this.http.get<any>(`${environment.apiUrl}item/order/?page=${page}`);
  }

  getOrder(id: string){
    console.log('get order' + id)
    return this.http.get<any>(`${environment.apiUrl}item/order?id=${id}`);
  }

  getOrderItems(id:string){
    return this.http.get<any>(`${environment.apiUrl}item/orderitem/?filter[orderid]=${id}`);
  }
  payment(id:string){
    return this.http.get<any>(`${environment.apiUrl}pay/?order=${id}`)
  }
}
