<div class="page-content">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><span>Create account</span></li>
      </ul>
    </div>
  </div>
  <div class="holder">
    <div class="container" *ngIf="!registerSuccess; else thanksPage">
      <div class="row justify-content-center">
        <div class="col-md-12 col-lg-12">
          <h1 class="text-center">Create an Account</h1>
          <div class="form-wrapper">
            <p>To take advantage of our speedy checkout, create an account with us now.</p>
            <form [formGroup]="registerForm" (ngSubmit)="submit()">
              <div class="row register-title"><div class="col-sm-12">       <h2>Account Details</h2></div></div>
              <div class="row justify-content-md-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-secondary " (click)="setUserType('user')" [ngClass]="{'active': f.usertype.value == 'user'}" > Regular User </button>
                  <button type="button" class="btn btn-secondary"(click)="setUserType('museum')" [ngClass]="{'active': f.usertype.value == 'museum'}">Museum</button>
                  <button type="button" class="btn btn-secondary"(click)="setUserType('gallery')"[ngClass]="{'active': f.usertype.value == 'gallery'}">Gallery</button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <input formControlName="firstname" id= "firstname" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.firstname.invalid }" placeholder="First name">
                    <div *ngIf="f.firstname.invalid && submitted">
                      <div *ngIf="f.firstname.errors?.required" class="invalid-feedback">First Name is required.</div>
                      <div *ngIf="f.firstname.errors?.minlength" class="invalid-feedback">First Name must be at least 3 characters long.</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input formControlName="lastname" id= "lastname" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.lastname.invalid }" placeholder="Last name">
                    <div *ngIf="f.lastname.invalid && submitted">
                      <div *ngIf="f.lastname.errors?.required" class="invalid-feedback">Last Name is required.</div>
                      <div *ngIf="f.lastname.errors?.minlength" class="invalid-feedback">Last Name  must be at least 3 characters long.</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input formControlName="email" id= "email" type="text" class="form-control" [ngClass]="{'is-invalid': (submitted && f.email.invalid) || duplicateEmail}" placeholder="E-mail">
                    <div *ngIf="f.email.invalid && submitted">
                      <div *ngIf="f.email.errors?.required" class="invalid-feedback">Email is required.</div>
                      <div *ngIf="f.email.errors?.email" class="invalid-feedback">Not a valid email address.</div>
                    </div>
                    <div *ngIf="duplicateEmail">
                      <div class="invalid-feedback">This email address is already registered</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input formControlName="phone" id= "phone" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.phone.invalid }" placeholder="Phone">
                    <div *ngIf="f.phone.invalid && submitted">
                      <div *ngIf="f.phone.errors?.required" class="invalid-feedback">Phone is required.</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input formControlName="password" id= "password" type="password" class="form-control" [ngClass]="{'is-invalid': submitted && f.password.invalid }"  placeholder="Password">
                    <div *ngIf="f.password.invalid && submitted">
                      <div *ngIf="f.password.errors?.required" class="invalid-feedback">Password is required.</div>
                      <div *ngIf="f.password.errors?.minlength" class="invalid-feedback">Password  must be at least 6 characters long..</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input formControlName="passwordConfirm" id= "passwordConfirm" type="password" class="form-control" [ngClass]="{'is-invalid': submitted && f.passwordConfirm.invalid }"  placeholder="Confirm Password">
                    <div *ngIf="f.passwordConfirm.invalid && submitted">
                      <div *ngIf="f.passwordConfirm.errors?.required" class="invalid-feedback">Confirm Password is required.</div>
                      <div *ngIf="f.passwordConfirm.errors?.mustMatch" class="invalid-feedback">Passwords must match.</div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="row register-title"><div class="col-sm-12">       <h2>Address</h2></div></div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input formControlName="address1" id= "address1" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f.address1.invalid }" placeholder="Address">
                    <div *ngIf="f.address1.invalid && submitted">
                      <div *ngIf="f.address1.errors?.required" class="invalid-feedback">Address is required.</div>
                      <div *ngIf="f.address1.errors?.minlength" class="invalid-feedback">Address must be at least 6 characters long.</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input formControlName="address2" id= "address2" type="text" class="form-control" placeholder="Address 2">
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group select-wrapper select-wrapper-sm">
                        <ng-select class="form-control custom-ng " [ngClass]="{'is-invalid': submitted && f.countryid.invalid }"
                        [items]="countries"
                        placeholder={{countryPlaceholder}}
                        (clear) = "countryChanged()"
                        (change) = "countryChanged()"
                        typeToSearchText =" Type to search.."
                        [minTermLength] = 3
                        [typeahead] = countryInput
                        [clearSearchOnAdd] = true
                        [closeOnSelect] = true
                        [searchable]="true"
                        bindLabel="name"
                        bindValue="id"
                        loadingText="Loading..."
                        formControlName="countryid">
                    </ng-select>
                    <div *ngIf="f.countryid.invalid && submitted">
                      <div  *ngIf="f.countryid.errors?.required" class="invalid-feedback">Country is required.</div>
                    </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group select-wrapper select-wrapper-sm" >
                        <ng-select class="form-control custom-ng" [ngClass]="{'is-invalid': submitted && f.regionid.invalid }"
                        [items]="regions"
                        placeholder={{regionPlaceholder}}
                        (clear) = "regionChanged()"
                        (change) = "regionChanged()"
                        typeToSearchText =" Type to search.."
                        [minTermLength] = 3
                        [typeahead] = regionInput
                        [searchable]="true"
                        bindLabel="name"
                        bindValue="id"
                        loadingText="Loading..."
                        formControlName="regionid">
                    </ng-select>
                    <div *ngIf="f.regionid.invalid && submitted">
                      <div  *ngIf="f.regionid.errors?.required" class="invalid-feedback">Region is required.</div>
                    </div>
                      </div>
                    </div>
                    </div>
                    <div class='row'>
                      <div class="col-sm-6">
                        <div class="form-group select-wrapper select-wrapper-sm">
                          <ng-select class="form-control custom-ng" [ngClass]="{'is-invalid': submitted && f.cityid.invalid }"
                          [items]="cities"
                          placeholder={{cityPlaceholder}}
                          (clear) = "cityChanged()"
                          (change) = "cityChanged()"
                          typeToSearchText =" Type to search.."
                          [minTermLength] = 3
                          [typeahead] = cityInput
                          [searchable]="true"
                          bindLabel="name"
                          bindValue="id"
                          loadingText="Loading..."
                          formControlName="cityid">
                      </ng-select>
                      <div *ngIf="f.cityid.invalid && submitted">
                        <div  *ngIf="f.cityid.errors?.required" class="invalid-feedback">City is required.</div>
                      </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <input formControlName="postcode" id= "postcode" type="text" class="form-control" placeholder="ZipCode">
                    </div>
                  </div>
                </div>
                </div>
            </div>
            <div *ngIf="f.usertype.value !='user'">
            <div class="row register-title"><div class="col-sm-12"> <h2>Company Details</h2></div></div>
            <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input type="text" class="form-control" formControlName="company" placeholder="Company">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input type="text" class="form-control" formControlName="vat" placeholder="VatNo">
                    </div>
                  </div>
              </div>
            </div>
              <div class="clearfix">
                <input id="acceptTerms" name="acceptTerms" type="checkbox" formControlName="acceptTerms">
                <label for="acceptTerms">By registering your details you agree to our <a href="#" class="custom-color"  >Terms and Conditions</a> and <a href="#" class="custom-color" data-fancybox data-src="#modalCookies">Cookie Policy</a></label>
                <div *ngIf="f.acceptTerms.invalid && submitted">
                  <div  *ngIf="f.acceptTerms.errors?.requiredTrue" class="invalid-feedback">Please indicate that you have read and agree to the Terms and Conditions and Cookie Policy</div>
                </div>
              </div>
              <div class="text-center">
                <button class="btn" (click)="submit()" type="submit">Create an account</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <ng-template #thanksPage>
      <div class="container">
      <div class="thanks-bg" style="min-height: 256px;">
        <div class="thanks-text">

          <div class="txt2">Thank You for Registration!</div>
        </div>
        <svg id="morphing" xmlns="http://www.w3.org/2000/svg" width="500" height="500" viewBox="0 0 600 600">
          <g transform="translate(50,50)">
            <path class="p" d="M100.85970353677334 0.580783954804464C130.25830177302544 1.8624096398627834 151.24145419376538 23.205520796203057 169.8216005431277 46.165632918491596C188.64734413436588 69.43019587971239 206.677477882619 94.79307705758218 200.79134916349517 124.11335756664818C194.981754386176 153.5907264593599 169.0964662309831 174.03763372462964 141.53320814159073 185.88591601399827C115.5109650850986 197.16364197499803 86.71749290624176 196.17745495768838 60.81933930816442 184.55559427895906C33.65532020191631 172.38850723726532 9.871079067848285 152.61686667733503 2.5202514837004655 123.74907787461775C-5.039701555926479 94.355360292215 5.075872787004666 63.571268868155734 24.01644705161926 39.848187193202094C42.91495044216112 16.177462453755048 70.71161166963874 -0.9191133558702234 100.85970353677334 0.580783954804464Z"></path>
          </g>
        </svg>
      </div>
      <div class="thanks-info text-center">
        <span class="btn" (click)="changeMiniAccountStatus($event, true)">Login Noow</span>
      </div>
    </div>
    </ng-template>
  </div>
</div>
