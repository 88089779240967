<div class="page-content" style="min-height: 226px;">
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><span>Artists</span></li>
      </ul>
    </div>
  </div>
  <div class="holder mt-0 py-3 py-sm-5 py-md-10 bg-cover lazyloaded" >
    <div class="container">
      <div class="row">
        <div class="col-11 col-md-9 col-xl-9">
          <div class="page-title-bg py-md-3 py-xl-6" style="color:white;">
            <h1 style="color:white;">ABOUT US</h1>
            <p class="d-none d-md-block">Founded in 2021,  ArTourAfrica is the premier one-stop shop for Art pieces – Painting, Sculptures, Clothing and fashion accessories that originate from the African continent of from African Diaspora around the world.</p>

            <p class="d-none d-md-block">It was founded from the synergy of an avid art enthusiast, former teacher, band leader, singer, and software developer – Ben Waswa, and a University-trained and experienced art teacher with hundreds of works under her belt – Joyce Olang.</p>
            <p class="d-none d-md-block">It is an effort to provide some sort of meeting place – a market – to exchange ideas about African art, provided through comments, and sell/purchase art pieces done by African artists particularly, who may have not have a good avenue to showcase their talent and make some money from sales.</p>

            <p class="d-none d-md-block"><i><b>Our support is available 08.00 – 17.00 GMT + 2 (EST).<br>
                  We usually get back to you within 24 hours.</b></i></p>
          </div>
        </div>
      </div>
    </div>
  <picture style="display: none;"><source data-srcset="./assets/images/about-bg.jpg" sizes="1865px" srcset="./assets/images/about-bg.jpg"><img alt="" class="" data-sizes="auto" data-parent-fit="cover" sizes="1865px"></picture></div>
  <div class="holder">
    <div class="container">
      <div class="title-wrap text-center">
        <h2 class="h1-style">Why Shop With Us?</h2>
      </div>
      <div class="text-icn-blocks-row">
        <div class="text-icn-block">
          <div class="icn">
            <i class="icon-shopping-1"></i>
          </div>
          <div class="text">
            Complete buyer supply store
          </div>
        </div>
        <div class="text-icn-block">
          <div class="icn">
            <i class="icon-box-1"></i>
          </div>
          <div class="text">
            Same day dispatch on all orders
          </div>
        </div>
        <div class="text-icn-block">
          <div class="icn">
            <i class="icon-delivery-truck"></i>
          </div>
          <div class="text">
            Free delivery on all orders
          </div>
        </div>
        <div class="text-icn-block">
          <div class="icn">
            <i class="icon-call-center"></i>
          </div>
          <div class="text">
            Professional advice and great support
          </div>
        </div>
        <div class="text-icn-block d-none d-sm-block">
          <div class="icn">
            <i class="icon-tag"></i>
          </div>
          <div class="text">
            Fall savings are in the air
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder py-4 py-md-7 bgcolor">
    <div class="container">
      <div class="row fact-blocks-row lazyloaded">
        <div class="col-sm-4">
          <div class="fact-block">
            <div class="number"><span data-count="90">90</span>%</div>
            <div class="title">Of excellent reviews</div>
            <div class="text">Nor again is there anyone who loves or pursues or desires
              to obtain pain of itself, because it is pain, but because occasionally in which toil and pain can procure
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="fact-block">
            <div class="number js-count-to"><span data-count="1545">1545</span></div>
            <div class="title">More sales</div>
            <div class="text">Nor again is there anyone who loves or pursues or desires
              to obtain pain of itself, because it is pain, but because occasionally in which toil and pain can procure
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="fact-block">
            <div class="number js-count-to"><span data-count="100">100</span>%</div>
            <div class="title">Happy customers</div>
            <div class="text">Nor again is there anyone who loves or pursues or desires
              to obtain pain of itself, because it is pain, but because occasionally in which toil and pain can procure
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder holder-subscribe-full lazyloaded">
    <div class="circle_decor_wrap">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 150 150" style="enable-background:new 0 0 150 150; " xml:space="preserve" class="circle_decor circle_decor--tl">
        <defs>
          <filter x="0" y="0" width="100%" height="100%" id="dropshadow_1_003" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2"></feGaussianBlur>
            <feOffset dx="-3" dy="0" result="offsetblur"></feOffset>
            <feMerge>
              <feMergeNode></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <filter x="0" y="0" width="100%" height="100%" id="dropshadow_2_003" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2"></feGaussianBlur>
            <feOffset dx="3" dy="0" result="offsetblur"></feOffset>
            <feMerge>
              <feMergeNode></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <clipPath id="circle_decor_mask_003">
            <circle r="73.5" cx="75" cy="75"></circle>
          </clipPath>
        </defs>
        <path fill="#55C1AE" d="M74.933,148.5c-9.9,0-19.533-1.95-28.583-5.783c-8.75-3.699-16.6-9-23.333-15.734c-6.75-6.731-12.034-14.582-15.733-23.332C3.45,94.6,1.5,84.967,1.5,75.066c0-9.9,1.95-19.533,5.783-28.583c3.7-8.75,9-16.6,15.733-23.333S37.6,11.116,46.35,7.417c9.05-3.833,18.684-5.783,28.583-5.783c9.901,0,19.534,1.95,28.584,5.783c8.75,3.7,16.6,9,23.332,15.733c6.733,6.733,12.032,14.583,15.733,23.333c3.832,9.067,5.783,18.684,5.783,28.583c0,9.9-1.951,19.534-5.783,28.584c-3.701,8.75-9,16.6-15.733,23.332c-6.732,6.733-14.582,12.033-23.332,15.733C94.467,146.55,84.834,148.5,74.933,148.5z M75,13.783C41.25,13.783,13.783,41.25,13.783,75S41.25,136.217,75,136.217S136.217,108.75,136.217,75S108.75,13.783,75,13.783z"></path>
        <circle r="5.8" cx="75" cy="7.8" fill-opacity="0.25" style="filter:url(#dropshadow_1_003)" clip-path="url(#circle_decor_mask_003)"></circle>
        <circle r="5.8" cx="74" cy="142.5" fill-opacity="0.25" style="filter:url(#dropshadow_2_003)" clip-path="url(#circle_decor_mask_003)"></circle>
        <path fill="#55C1AE" d="M81.101,7.712c0,3.346-2.761,6.059-6.164,6.059l0,0c-3.404,0-6.164-2.713-6.164-6.059l0,0c0-3.345,2.76-6.058,6.164-6.058l0,0C78.34,1.654,81.101,4.367,81.101,7.712L81.101,7.712z"></path>
        <path fill="#55C1AE" d="M81.177,142.363c0,3.346-2.761,6.06-6.164,6.06l0,0c-3.404,0-6.164-2.714-6.164-6.06l0,0c0-3.345,2.76-6.058,6.164-6.058l0,0C78.416,136.306,81.177,139.019,81.177,142.363L81.177,142.363z"></path>
      </svg>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 150 150" style="enable-background:new 0 0 150 150; " xml:space="preserve" class="circle_decor circle_decor--br">
        <defs>
          <filter x="0" y="0" width="100%" height="100%" id="dropshadow_1_004" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2"></feGaussianBlur>
            <feOffset dx="-3" dy="0" result="offsetblur"></feOffset>
            <feMerge>
              <feMergeNode></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <filter x="0" y="0" width="100%" height="100%" id="dropshadow_2_004" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2"></feGaussianBlur>
            <feOffset dx="3" dy="0" result="offsetblur"></feOffset>
            <feMerge>
              <feMergeNode></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <clipPath id="circle_decor_mask_004">
            <circle r="73.5" cx="75" cy="75"></circle>
          </clipPath>
        </defs>
        <path fill="#55C1AE" d="M74.933,148.5c-9.9,0-19.533-1.95-28.583-5.783c-8.75-3.699-16.6-9-23.333-15.734c-6.75-6.731-12.034-14.582-15.733-23.332C3.45,94.6,1.5,84.967,1.5,75.066c0-9.9,1.95-19.533,5.783-28.583c3.7-8.75,9-16.6,15.733-23.333S37.6,11.116,46.35,7.417c9.05-3.833,18.684-5.783,28.583-5.783c9.901,0,19.534,1.95,28.584,5.783c8.75,3.7,16.6,9,23.332,15.733c6.733,6.733,12.032,14.583,15.733,23.333c3.832,9.067,5.783,18.684,5.783,28.583c0,9.9-1.951,19.534-5.783,28.584c-3.701,8.75-9,16.6-15.733,23.332c-6.732,6.733-14.582,12.033-23.332,15.733C94.467,146.55,84.834,148.5,74.933,148.5z M75,13.783C41.25,13.783,13.783,41.25,13.783,75S41.25,136.217,75,136.217S136.217,108.75,136.217,75S108.75,13.783,75,13.783z"></path>
        <circle r="5.8" cx="75" cy="7.8" fill-opacity="0.25" style="filter:url(#dropshadow_1_004)" clip-path="url(#circle_decor_mask_004)"></circle>
        <circle r="5.8" cx="74" cy="142.5" fill-opacity="0.25" style="filter:url(#dropshadow_2_004)" clip-path="url(#circle_decor_mask_004)"></circle>
        <path fill="#55C1AE" d="M81.101,7.712c0,3.346-2.761,6.059-6.164,6.059l0,0c-3.404,0-6.164-2.713-6.164-6.059l0,0c0-3.345,2.76-6.058,6.164-6.058l0,0C78.34,1.654,81.101,4.367,81.101,7.712L81.101,7.712z"></path>
        <path fill="#55C1AE" d="M81.177,142.363c0,3.346-2.761,6.06-6.164,6.06l0,0c-3.404,0-6.164-2.714-6.164-6.06l0,0c0-3.345,2.76-6.058,6.164-6.058l0,0C78.416,136.306,81.177,139.019,81.177,142.363L81.177,142.363z"></path>
      </svg>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-auto">
          <div class="subscribe-form-title-lg">Newsletter</div>
          <div class="subscribe-form-title-sm">Subscribe to our weekly newsletter.</div>
        </div>
        <div class="col">
          <div class="subscribe-form">
            <form action="#">
              <div class="form-inline">
                <div class="form-control-wrap">
                  <input type="text" class="form-control" placeholder="Type your e-mail...">
                  <svg preserveAspectRatio="none">
                    <rect x="2" y="2" rx="6" height="100%" width="100%"></rect>
                  </svg>
                  <span class="bottom"></span>
                </div>
                <button type="submit" class="btn btn--lg">subscribe</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
