import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClickOutsideModule} from 'ng-click-outside';
import { OwlModule } from 'ngx-owl-carousel';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HeaderComponent } from './_shared/layout/header/header.component';
import { FooterComponent } from './_shared/layout/footer/footer.component';
import { HomepageComponent } from './_shared/layout/homepage/homepage.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProductsListSidebarComponent } from './products-list-sidebar/products-list-sidebar.component';
import { NavComponent } from './_shared/layout/nav/nav.component';
import { CartComponent } from './cart-system/cart/cart.component';
import { CheckoutComponent } from './cart-system/checkout/checkout.component';
import { ProductComponent } from './product/product.component';
import { GalleryComponent } from './gallery/gallery.component';
import { MuseumComponent } from './museum/museum.component';
import { MuseumGalleriesListComponent } from './museum-galleries-list/museum-galleries-list.component';
import { RegisterComponent } from './user/register/register.component';
import { ProfileComponent } from './user/profile/profile.component';
import { LayoutComponent } from './_shared/layout/layout.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ArtistsComponent } from './artists/artists.component';
import { ArtworksComponent } from './artworks/artworks.component';
import { HeaderSliderComponent } from './header-slider/header-slider.component';
import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { MobmenuComponent } from './hider-side-panel/mobmenu/mobmenu.component';
import { DropdwnAccountComponent } from './hider-side-panel/dropdwn-account/dropdwn-account.component';
import { DropdwnMinicartComponent } from './hider-side-panel/dropdwn-minicart/dropdwn-minicart.component';
import { GalleriesComponent } from './galleries/galleries.component';
import { MuseumsComponent } from './museums/museums.component';
import { AccountDetailsComponent } from './user/account-details/account-details.component';
import { LoginComponent } from './user/login/login.component';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddressesComponent } from './user/addresses/addresses.component';
import { AlertComponent } from './_components/alert.component';
import { AddressFormComponent } from './user/addresses/address-form/address-form.component';
import { AppInitService } from './_helpers/app-init.service';
import { LoadingComponent } from './_shared/loading/loading.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ArtistComponent } from './artist/artist.component';
import { OrdersComponent } from './user/orders/orders.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { OrderComponent } from './user/order/order.component';
import { OrderCompleteComponent } from './cart-system/order-complete/order-complete.component';
import { MuseumVisitComponent } from './museum-visit/museum-visit.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';
import { PaymentdoneComponent } from './paymentdone/paymentdone.component';
import { PaymentfailedComponent } from './paymentfailed/paymentfailed.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';

export function refreshUserDetails(appInitService: AppInitService){
  return (): Promise<any> => {
    return appInitService.Init();
  }
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    ProductsListComponent,
    ProductsListSidebarComponent,
    NavComponent,
    CartComponent,
    CheckoutComponent,
    ProductComponent,
    GalleryComponent,
    MuseumComponent,
    MuseumGalleriesListComponent,
    RegisterComponent,
    ProfileComponent,
    LayoutComponent,
    AboutusComponent,
    ArtistsComponent,
    ArtworksComponent,
    HeaderSliderComponent,
    ContactComponent,
    TermsComponent,
    MobmenuComponent,
    DropdwnAccountComponent,
    DropdwnMinicartComponent,
    GalleriesComponent,
    MuseumsComponent,
    AccountDetailsComponent,
    LoginComponent,
    AddressesComponent,
    AlertComponent,
    AddressFormComponent,
    LoadingComponent,
    ArtistComponent,
    OrdersComponent,
    ThankYouComponent,
    OrderComponent,
    OrderCompleteComponent,
    MuseumVisitComponent,
    VideoPlayerComponent,
    PaymentdoneComponent,
    PaymentfailedComponent,
    HowToBuyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClickOutsideModule,
    OwlModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgxSimpleCountdownModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
    , AppInitService,
    { provide: APP_INITIALIZER, useFactory: refreshUserDetails, deps: [AppInitService], multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
