import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArtistsService {

  constructor(private http: HttpClient) { }


  getArtists(page?:number) { // get<Contact>
    let queryString = '';
    if(page){
      queryString = `?page=${page}`;
    }
    return this.http.get(`${environment.apiUrl}item/artist/${queryString}`);
  }
  getArtist(id: string){
    return this.http.get(`${environment.apiUrl}item/artist/?id=${id}`);
  }

}
