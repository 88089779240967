import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input, SimpleChanges, SimpleChange} from '@angular/core';
import videojs from 'video.js';
declare var require: any;
require('videojs-contrib-quality-levels');
require('videojs-hls-quality-selector');
require('@silvermine/videojs-quality-selector')(videojs);
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
   @Input() playerOptions: any;
   @Input() videoSources: any;
   @Input() moviePoster: any;


  public player!: videojs.Player;

//https://silentsudo.gitlab.io/post/angular/2020-05-04-videojs-quality-selector-hls-streaming/
  constructor() { }

  ngOnInit(): void {
    console.log('player loaded');
      console.log(this.playerOptions);
  }
  ngOnChanges(changes: { [propName: string]: SimpleChange }){

    console.log(changes.videoSources.currentValue);
    if( changes.videoSources && changes.videoSources.previousValue !== changes.videoSources.currentValue ) {
      console.log('source changed');
      console.log(changes.videoSources.currentValue);
      this.loadPlayerSource(changes.videoSources.currentValue);
    }
    if( changes.moviePoster && changes.moviePoster.previousValue !== changes.moviePoster.currentValue ) {
      console.log('moviePoster changed');
      console.log(changes.moviePoster.currentValue);
      //this.player.poster(changes.moviePoster.currentValue);
    }
    if( changes.playerOptions && changes.playerOptions.previousValue !== changes.playerOptions.currentValue ) {
      console.log('playerOptions changed');
      console.log(changes.playerOptions.currentValue);
      //this.player.dispose();
      //this.initPlayer();
    }

    //this.initPlayer();
  }
  ngAfterViewInit(){
    console.log('init player');
    this.initPlayer();
  }

  initPlayer(){
    if(this.playerOptions){
      console.log(this.playerOptions);
      this.playerOptions.sources = this.videoSources;
      this.player = videojs('my-video', this.playerOptions, function onPlayerReady(this: any){
        console.log(this.playerOptions);
        console.log('Player Ready');
        // tslint:disable-next-line: one-variable-per-declaration
        const myPlayer = this, id = myPlayer.id();
        console.log(id);
      });
    }
    // tslint:disable-next-line: typedef

    if(this.videoSources){
      this.loadPlayerSource(this.videoSources);
    }
  }
  loadPlayerSource(sources: any){
  console.log('loading new sources');
  if(this.player){
    this.player.poster(this.moviePoster);
    this.player.src(sources);
    videojs.log();
    //this.player.load();
  }else{
    this.initPlayer();
  }
  }


  ngOnDestroy(): void{
    if (this.player != null){
      this.player.dispose();
      console.log('player disposed');
    }
  }

}
