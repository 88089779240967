import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ProductsService } from 'app/_services/products.service';
import { first } from 'rxjs/operators';
import { CartService } from 'app/_services/cart.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-galleries',
  templateUrl: './galleries.component.html',
  styleUrls: ['./galleries.component.css']
})
export class GalleriesComponent implements OnInit {
  appliedFilters: any = { isticket: 1, prodtypeid: 5};
  totalItems = 0;
  noOfPages = 0;
  currentPage = 1;
  products!: any[];
  constructor(
    private spinner: NgxSpinnerService,
    private productsService: ProductsService,
    private cartService: CartService,
  ) { }

  ngOnInit(): void {this.getItems(this.appliedFilters);
  }
  showSpecificSpinner(name: string) {
    console.log('show spinner' + name);
    this.spinner.show(name,{
      type: 'ball-clip-rotate',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#98570c',
      fullScreen: false
    });
  }

  hideSpecificSpinner(name: string) {
     this.spinner.hide(name);
  }
  getItems(filters?: any){

    this.productsService.getProducts(filters).pipe(first()).subscribe((result: any) => {
      this.products! = result.data;
      this.products.forEach((product:any) => {
          this.showSpecificSpinner(product.id);
      })
      this.totalItems = result.meta.count;
      this.noOfPages = Math.ceil(this.totalItems / 12);
      console.log(this.totalItems);
      console.log(this.products);
    });

  }
  changePage(page: number){
    this.currentPage = page;
    this.getItems(this.appliedFilters);
    window.scroll(0, 0);
    // console.log(page);
  }

}
