import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'app/_services/alert.service';
import { CartService } from 'app/_services/cart.service';
import { OrderService } from 'app/_services/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  orders: any[] = [];
  included: any;
  totalItems = 0;
  noOfPages = 0;
  currentPage = 1;
  constructor(
    private orderService: OrderService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cartService: CartService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.getOrders();
  }
  showSpinner(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: '#98570c',
        color: 'white',
        fullScreen: false
      }
    );
  }
  hideSpinner(){
    this.spinner.hide();
  }
  getOrders(page?:number){
    this.showSpinner();
    this.orderService.getOrders(page).pipe(first()).subscribe((response:any) =>{
     if(response.data){
       this.orders = response.data;
       this.included = response.included;
       this.totalItems = response.meta.count;
       this.noOfPages = Math.ceil(this.totalItems / 12);
       this.hideSpinner();
     }
    });
  }
  changePage(page: number){
    console.log(page);
    this.currentPage = page;
    this.getOrders(this.currentPage);
    // console.log(page);
  }

  getAddressById(id: number){
    var result = this.included.filter(function(elem: any) {
      return (elem.type === 'address' && elem.id === id);
    })[0];
    return result;
  }
  payOrder(orderId: string){
    this.orderService.payment(orderId).pipe(first()).subscribe((response:any)=>{
      if(response.success){
        console.log(response.link);
        this.router.ngOnDestroy();
        window.location.href = response.link;
        this.cartService.emptyCart();
      }else{
        console.log(response);
        this.alertService.error('Something Wrong! Please contact the Administrator');
      }
    })
  }
  getFullCity(address: any) {
    const country = this.included.find((elem: any) => {
       return elem.id === address.countryid;
    });
    const region = this.included.find((elem: any) => {
      return elem.id === address.regionid;
    });
    const city = this.included.find((elem: any) => {
     return elem.id === address.cityid;
   });
    return (`${city.attributes.name}, ${region.attributes.name}, ${country.attributes.name}`);
  }

}
