<div class="dropdn-content account-drop" [ngClass]="{'is-opened':miniAccountStatus}" id="dropdnAccount" (clickOutside)="changeMiniAccountStatus(false)" [delayClickOutsideInit]="true">
  <div class="dropdn-content-block" >
    <div class="dropdn-close"><span class="js-dropdn-close" (click)="changeMiniAccountStatus(false)" >Close</span></div>
    <div *ngIf="!currentUser.firstname; else userMenu">
    <ul>
      <li><a routerLink="/register" (click)="changeMiniAccountStatus(false)"><span>Register</span><i class="icon-user2"></i></a></li>
    </ul>
    <div class="dropdn-form-wrapper">
      <h5>Quick Login</h5>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <input type="text" class="form-control form-control--sm" formControlName="username" [ngClass]="{'is-invalid': submitted && f.username.invalid  }" placeholder="Enter your username">
          <div *ngIf="f.username.invalid && submitted">
            <div *ngIf="f.username.errors?.required" class="invalid-feedback">Username is required.</div>
          </div>
        </div>
        <div class="form-group">
          <input type="password" class="form-control form-control--sm" formControlName="password" [ngClass]="{'is-invalid': submitted && f.password.invalid }" placeholder="Enter your password">
          <div *ngIf="f.password.invalid && submitted">
            <div *ngIf="f.password.errors?.required" class="invalid-feedback">Password is required.</div>
          </div>
        </div>
        <div *ngIf="loginError">
          <div class="invalid-feedback">Invalid credentials!</div>
        </div>
        <button class="btn btn-primary" type="submit" [disabled]="loading">
          <span *ngIf ="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Login
        </button>
      </form>
    </div>
  <div ngClass="{'drop-overlay js-dropdn-close':!miniAccountStatus}" (click)="changeMiniAccountStatus(false)"></div>
</div>
<ng-template #userMenu>
  <ul>
    <li  class='welcome-miniacc'><span><b>Welcome  <span class="welcome-name">{{currentUser.firstname}} {{ currentUser.lastname}}</span></b></span></li>
  </ul>
  <ul>
    <li><a routerLink="/profile" (click)="changeMiniAccountStatus(false)" ><span>Profile</span><i class="icon-user2"></i></a></li>
  </ul>
  <ul class='logoutLink'>
    <li ><a style="cursor:pointer" (click)="logout()"><span>LogOut</span><i class="icon-login"></i></a></li>
  </ul>
</ng-template>
</div>
