import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { ArtistsComponent } from './artists/artists.component';
import { ArtistComponent} from './artist/artist.component';
import { ArtworksComponent } from './artworks/artworks.component';
import { CheckoutComponent } from './cart-system/checkout/checkout.component';
import { HomepageComponent } from './_shared/layout/homepage/homepage.component';
import { CartComponent } from './cart-system/cart/cart.component';
import { ProfileComponent } from './user/profile/profile.component';
import { ProductComponent } from './product/product.component';
import { MuseumsComponent } from './museums/museums.component';
import { GalleriesComponent } from './galleries/galleries.component';
import { RegisterComponent } from './user/register/register.component';
import { TermsComponent } from './terms/terms.component';
import { AddressesComponent } from './user/addresses/addresses.component';
import { AuthGuard } from 'app/_helpers/auth.guards';
import { OrdersComponent } from './user/orders/orders.component';
import { OrderComponent } from './user/order/order.component';
import { GalleryComponent } from './gallery/gallery.component';
import { MuseumComponent } from './museum/museum.component';
import { OrderCompleteComponent } from './cart-system/order-complete/order-complete.component';
import { MuseumVisitComponent } from './museum-visit/museum-visit.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { PaymentdoneComponent } from './paymentdone/paymentdone.component';
import { PaymentfailedComponent } from './paymentfailed/paymentfailed.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';
const routes: Routes = [
  {
    path: 'home',
    component: HomepageComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'addresses',
    component: AddressesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'museums',
    component: MuseumsComponent
  },
  {
    path: 'museum/:id',
    component: MuseumComponent
  },
  {
    path: 'museum-visit/:id',
    component: MuseumVisitComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'galleries',
    component: GalleriesComponent
  },
  {
    path: 'gallery/:id',
    component: GalleryComponent
  },
  {
    path: 'aboutus',
    component: AboutusComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'artists',
    component: ArtistsComponent
  },
  {
    path: 'artist/:id',
    component: ArtistComponent
  },
  {
    path: 'shop',
    component: ArtworksComponent
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'order-complete',
    component: OrderCompleteComponent
  },
  {
    path: 'product/:id',
    component: ProductComponent
  },
  {
    path: 'artist/:id',
    component: ArtistComponent
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order/:id',
    component: OrderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-and-conditions',
    component: TermsComponent
  },{
    path: 'video-player',
    component: VideoPlayerComponent
  },{
    path: 'paymentdone',
    component: PaymentdoneComponent
  },{
    path: 'paymentfailed',
    component: PaymentfailedComponent
  },
  {
    path:'howtobuy',
    component: HowToBuyComponent
  }
  ,
  {path: '**', redirectTo: 'home'}



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
