import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';;
import { Subscription } from 'rxjs';
import { GlobalChangesService } from '../../../_services/global-changes.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import { User } from 'app/_models/user';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  miniCartStatus = false;
  miniAccountStatus = false;
  mobileMenuStatus = false;
  currentUser!: any;
  miniCartSubscription!: Subscription;
  miniAccountSubscription!: Subscription;
  mobileMenuSubscription!: Subscription;
  currentUserSubscription!: Subscription;
  cartListSubscription!: Subscription;
  cart!: any;
  totalCartSubscription!: Subscription;
  totalCart!: number;
  constructor(
    private globalChangesService: GlobalChangesService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.currentUserSubscription = this.globalChangesService.accountDetails.subscribe(accountDetails => this.currentUser = accountDetails);
    this.miniCartSubscription = this.globalChangesService.miniCartStatus.subscribe(status => this.miniCartStatus = status);
    this.miniAccountSubscription = this.globalChangesService.miniAccountStatus.subscribe(status => this.miniAccountStatus = status);
    this.mobileMenuSubscription = this.globalChangesService.mobilMenuStatus.subscribe(status => this.mobileMenuStatus = status);
    this.cartListSubscription = this.globalChangesService.cartList.subscribe(cart => this.cart = cart);
    this.totalCartSubscription = this.globalChangesService.cartTotal.subscribe(totalCart => this.totalCart = totalCart);
    console.log('cart header');
    console.log(this.cart);
  }

  changeMiniCartStatus($event: Event, status:boolean){
    $event.stopPropagation();
    this.globalChangesService.setMiniCartStatus(status);
   }
   changeMiniAccountStatus($event: Event, status: boolean){
     $event.stopPropagation();
     this.globalChangesService.setMiniAccountStatus(true);
   }
   changeMobileMenuStatus($event: Event,status: boolean){
     $event.stopPropagation();
     this.globalChangesService.setMobileMenuStatus(status);
   }

}
