import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/_services/user.service';
import { Router } from '@angular/router';
import { User } from '../../_models/user';
import { AddressesService } from 'app/_services/addresses.service';
import { first } from 'rxjs/operators';
import { Subscription, Observable, Subject, pipe } from 'rxjs';
import { AlertService } from 'app/_services/alert.service';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit {

  loading = true;
  currentUser!: any;
  currentUserSubscription!: Subscription;
  formLoaded = false;
  addresses: any;
  included: any;
  constructor(
    private addressesService: AddressesService,
    private alertService: AlertService,
    private globalChangesService: GlobalChangesService,
    private spinner: NgxSpinnerService

  ) {

  }

  ngOnInit(): void {
    this.showSpinner();
    this.currentUserSubscription = this.globalChangesService.accountDetails.subscribe(accountDetails => this.currentUser = accountDetails);
    this.getAddresses();
    console.log(this.currentUser);

  }
  showSpinner(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: '#98570c',
        color: 'white',
        fullScreen: false
      }
    );
  }
  hideSpinner(){
    this.spinner.hide();
  }
   getAddresses(){
     this.loading = true;
     this.showSpinner();
     this.addressesService.getAddresses().pipe(first()).subscribe((response:any) =>{
      if(response.data){
        this.addresses = response.data;
        this.included = response.included;
        this.hideSpinner();
        this.loading = false;
      }
     });
   }

   getFullCity(address: any){
     let country = this.included.find((elem:any) => {
        return elem.id === address.countryid;
     });
     let region = this.included.find((elem:any)=>{
       return elem.id === address.regionid;
     });
     let city = this.included.find((elem:any)=>{
      return elem.id === address.cityid;
    });
     return (`${city.attributes.name}, ${region.attributes.name}, ${country.attributes.name}`);
   }

   getCountryName(address: any){
    let country = this.included.find((elem:any) => {
      return elem.id === address.countryid;
   });
    return country.attributes.name;
   }

   getRegionName(address: any){
    let region = this.included.find((elem:any) => {
      return elem.id === address.regionid;
   });
    return region.attributes.name;
   }
   getCityName(address: any){
    let city = this.included.find((elem:any) => {
      return elem.id === address.cityid;
   });
    return city.attributes.name;
   }
  deleteAddressDialog(id: number, confirm: boolean){
    const buttonsElement = document.getElementById(`row-buttons-${id}`) || null;
    const confirmElement = document.getElementById(`confirm-buttons-${id}`) || null;

    if (!confirm){
            // tslint:disable-next-line: no-non-null-assertion
      buttonsElement!.style.display = 'block';
            // tslint:disable-next-line: no-non-null-assertion
      confirmElement!.style.display = 'none';

    } else {
      // tslint:disable-next-line: no-non-null-assertion
      buttonsElement!.style.display = 'none';
      // tslint:disable-next-line: no-non-null-assertion
      confirmElement!.style.display = 'block';
    }
  }


  deleteAddress(id: string){
    const addressRowElement = document.getElementById(`address-row-${id}`) || null;
    addressRowElement !.style.display = 'none';
    this.addressesService.deleteAddress(id)
    .pipe(first())
    .subscribe((response:any) => {
      console.log(response.data.deleted);
      if(response.data.deleted && response.data.deleted === 1){
        this.alertService.success('Address successfully deleted');
      }else{
        this.alertService.error('Addres cannot be deleted!');
      }
    });
  }

  showForm(id: string){
    console.log('form-'+id)
    const addressRowElement = document.getElementById(`address-row-${id}`) || null;
    const formElement = document.getElementById(`form-${id}`) || null;

    addressRowElement && (addressRowElement.style.display = 'none');

    formElement && (formElement.style.display = 'block');
  }

  closeForm(id: string){
    console.log(id);
    const addressRowElement = document.getElementById(`address-row-${id}`) || null;
    const formElement = document.getElementById(`form-${id}`) || null;
    addressRowElement && (addressRowElement!.style.display = 'flex') ;
    formElement && (formElement!.style.display = 'none') ;
  }


}
