import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/_services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser.token) {
      console.log(currentUser);
      console.log('auth guard');
      return true;
    }
    console.log('NotLoggedIn');

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/home'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
