import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProducts(filters?: any) {
    let filtersQuery = '';
    let filterApplied = false;
    if (filters){
      console.log('Applied Filters');
      console.log(filters);

      if (filters.hasOwnProperty('artists') && filters.artists.length > 0){
        filterApplied = true;
        filtersQuery +=`&filter[artistid]=${filters.artists.toString()}`;
        console.log('Artists filter applied');
      }
      if (filters.hasOwnProperty('categories') && filters.categories.length > 0){
        filterApplied = true;
        console.log('Categories filters applied');
        filtersQuery += `&filter[categoryid]=${filters.categories.toString()}`;
        console.log('Artists filter applied');
      }
      if (filters.hasOwnProperty('page') && filters.page > 1){
        filterApplied = true;
        filtersQuery += `&page=${filters.page}`;
      }
      if(filters.hasOwnProperty('sort') && filters.sort !== ''){
        filterApplied = true;
        filtersQuery += `&sort=${filters.sort}`;
      }
      if(filters.hasOwnProperty('isticket')){
        filterApplied = true;
        filtersQuery += `&filter[isticket]=${filters.isticket}`;
      }
      if(filters.hasOwnProperty('prodtypeid')){
        filterApplied = true;
        filtersQuery += `&filter[prodtypeid]=${filters.prodtypeid}`;
      }
    }

      filtersQuery = filtersQuery.replace('&','');
      filtersQuery = '?' + filtersQuery;
      console.log(filtersQuery);
    return this.http.get(`${environment.apiUrl}item/product/${filtersQuery}`);
  }


  getCategories(){
    return this.http.get(`${environment.apiUrl}item/category/`);
  }

   getProduct(id: string){
    return this.http.get(`${environment.apiUrl}item/product/?id=${id}`);
  }
  getTickets(){
    return this.http.get(`${environment.apiUrl}item/ticket/`);
  }
}
