<div class="dropdn-content minicart-drop " [ngClass]="{'is-opened':miniCartStatus}" (clickOutside)="changeMiniCartStatus(false)" id="dropdnMinicart">
  <div class="dropdn-content-block">
    <div class="dropdn-close" ><span class="js-dropdn-close" (click)="changeMiniCartStatus(false)">Close</span></div>
    <div class="minicart-drop-content js-dropdn-content-scroll ps ps--theme_default" data-ps-id="6dbc0761-036f-f3aa-b74a-9b12748572dc">
      <div *ngIf="cart && cart.length > 0; else emptyCart">
      <div *ngFor="let product of cart" class="minicart-prd row">
        <div class="minicart-prd-image image-hover-scale-circle col">
          <a [routerLink]="['/product', product.productid]" ><img class="fade-up ls-is-cached lazyloaded" src="https://api.artourafrica.com/api/imgthumb/?size=80&id={{product.img}}" data-src="assets/images/nophoto.jpg" alt=""></a>
        </div>
        <div class="minicart-prd-info col" id="cartProductDetails-{{product.productid}}">
          <h2 class="minicart-prd-name"><a [routerLink]="['/product', product.productid]" >{{product.title}}</a></h2>
          <div class="minicart-prd-qty"><span class="minicart-prd-qty-label">Quantity:</span><span class="minicart-prd-qty-value">{{product.quantity}}</span></div>
          <div class="minicart-prd-price prd-price">
            <div class="price-old">${{(product.price*1.15).toFixed(1)}}</div>
            <div class="price-new">${{product.price}}</div>
          </div>
        </div>
        <div class="minicart-prd-action">
          <span class="js-product-remove" data-line-number="1"><i (click) ="removeItemFromCart($event,product.productid)" class="icon-recycle" style="cursor:pointer"></i></span>
        </div>
      </div>
    </div>
    <ng-template #emptyCart>
      <div class="minicart-empty js-minicart-empty ">
        <div class="minicart-empty-text">Your cart is empty</div>
        <div class="minicart-empty-icon">
          <i class="icon-shopping-bag"></i>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 306 262" style="enable-background:new 0 0 306 262;" xml:space="preserve">
            <path class="st0" d="M78.1,59.5c0,0-37.3,22-26.7,85s59.7,237,142.7,283s193,56,313-84s21-206-69-240s-249.4-67-309-60C94.6,47.6,78.1,59.5,78.1,59.5z"></path>
          </svg>
        </div>
      </div>
    </ng-template>
    <div class="ps__scrollbar-x-rail" style="left: 0px; bottom: 0px;"><div class="ps__scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__scrollbar-y-rail" style="top: 0px; right: 0px;"><div class="ps__scrollbar-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></div>
    <div class="minicart-drop-fixed js-hide-empty">
      <div class="loader-horizontal-sm js-loader-horizontal-sm" data-loader-horizontal=""><span></span></div>
      <div class="minicart-drop-total js-minicart-drop-total row no-gutters align-items-center">
        <div class="minicart-drop-total-txt col-auto heading-font">Subtotal</div>
        <div class="minicart-drop-total-price col" data-header-cart-total="">${{totalCart}}</div>
      </div>
      <div class="minicart-drop-actions">
        <a routerLink="/cart" class="btn btn--md btn--grey"><i class="icon-basket"></i><span>Cart Page</span></a>
        <a *ngIf="cart && cart.length > 0" routerLink="/checkout" class="btn btn--md"><i class="icon-checkout"></i><span>Check out</span></a>
      </div>
      <ul class="payment-link mb-2">
        <li><i class="icon-amazon-logo"></i></li>
        <li><i class="icon-visa-pay-logo"></i></li>
        <li><i class="icon-skrill-logo"></i></li>
        <li><i class="icon-klarna-logo"></i></li>
        <li><i class="icon-paypal-logo"></i></li>
        <li><i class="icon-apple-pay-logo"></i></li>
      </ul>
    </div>
  </div>
  <div ngClass="{'drop-overlay js-dropdn-close':!miniCartStatus}" (click)="changeMiniCartStatus(false)"></div>
</div>
