
<owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding']">
  <div class="slider-item" *ngFor="let slide of slides">

      <img class="slider-image" [src]="slide.src" alt="Card image cap">
        <ul class="captions">
          <li class="slider-title">{{slide.headline}}</li>
          <li class="slider-caption slider-text">{{slide.content}}</li>
        </ul>

  </div>
</owl-carousel>
