import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { GlobalChangesService } from './global-changes.service';
import { AlertService } from './alert.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private globalChangesService: GlobalChangesService,
    private alertService: AlertService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')  || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    const body = new HttpParams()
      .set('email', email)
      .set('password', password);
    return this.http.post<any>(`${environment.apiUrl}login`, body.toString(),{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify({token: user.token}));
          this.currentUserSubject.next(user);
          this.globalChangesService.setCart(JSON.parse(localStorage.getItem('products') || '[]'));
          this.globalChangesService.setTotalCart(JSON.parse(localStorage.getItem('totalCart') || '0'));
          this.alertService.success('Successful Login');
        } else {
          this.alertService.error('Login Failed!');
          return user;

        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out

    this.globalChangesService.setAccountDetails(null);

    localStorage.removeItem('currentUser');
    this.currentUserSubject.next({});
    window.location.reload();
    this.globalChangesService.setCart(JSON.parse(localStorage.getItem('products') || '[]'));
    this.globalChangesService.setTotalCart(JSON.parse(localStorage.getItem('totalCart') || '0'));
    this.alertService.success('You are now logged out');
  }

  getUserDetails1(){
    return this.http.get(`${environment.apiUrl}item/user`);
  }

  isLoggedIn(): boolean{
    const currentUser = this.currentUserValue;
    if (currentUser.token) {
      console.log(currentUser);
      console.log('auth guard');
      return true;
    }else{
      return false;
    }
  }
  getUserDetails() {

    return this.http.get<any>(`${environment.apiUrl}item/user`)
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response && response.global.user) {

          let currentUser = response.global.user;


          let userIncluded = response.userincluded
          let userCountry = userIncluded.filter((obj:any) => {
            return obj.type === 'country';
          });
          let userRegion = userIncluded.filter((obj:any) => {
            return obj.type === 'region';
          });
          let userCity = userIncluded.filter((obj:any) => {
            return obj.type === 'city';
          });
          userCountry = {
            id: userCountry[0].attributes.id,
            name: userCountry[0].attributes.name
          }
          userRegion = {
            id: userRegion[0].attributes.id,
            name: userRegion[0].attributes.name
          }
          userCity = {
            id: userCity[0].attributes.id,
            name: userCity[0].attributes.name
          }

          currentUser.country = userCountry;
          currentUser.region = userRegion;
          currentUser.city = userCity;
          console.log('STORED USER');
          console.log(currentUser);
          this.globalChangesService.setAccountDetails(currentUser);
                } else {
          return false;
        }

        return response.global.user;
      }));
  }



}
