<div class="mobilemenu js-push-mbmenu" [ngClass]="{'active':mobileMenuStatus}" (click)="changeMobileMenuStatus(false)" (clickOutside)="changeMobileMenuStatus(false)">
  <div class="mobilemenu-content loaded" style="">
    <div class="mobilemenu-close mobilemenu-toggle active" (click)="changeMobileMenuStatus(false)">Close</div>
    <div class="mobilemenu-scroll ps ps--theme_default" data-ps-id="a2e5d403-f105-c3f6-cda2-5202f55add61">
      <div class="mobilemenu-search"></div>
      <div class="nav-wrapper show-menu" style="height: 560px;">
        <div class="nav-toggle">
          <span class="nav-back"><i class="icon-angle-left"></i></span>
          <span class="nav-title">Menu</span>
          <a href="" class="nav-viewall">view all</a>
        </div>
        <ul class="nav nav-level-1">
          <li><a routerLink="/home" routerLinkActive="active">Home<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
          <li><a routerLink="/aboutus" routerLinkActive="active">About Us<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
          <li><a routerLink="/artists" routerLinkActive="active">Artists<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
          <li><a routerLink="/museums" routerLinkActive="active">Museums<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
          <li><a routerLink="/galleries" routerLinkActive="active">Galleries<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
          <li><a routerLink="/shop" routerLinkActive="active">Artwork<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
          <li><a routerLink="/contact" routerLinkActive="active">Contact Us<span class="arrow"><i class="icon-angle-right"></i></span></a></li>
        </ul>
      </div>
  </div>
</div>
