import { HttpClient } from '@angular/common/http';
import { Injectable }  from '@angular/core';
import { GlobalChangesService } from 'app/_services/global-changes.service';
import { environment } from '../../environments/environment';
@Injectable()
export class AppInitService {

    constructor(
      private http: HttpClient,
      private globalChangesService: GlobalChangesService,
    ) {
    }

// tslint:disable-next-line: typedef
Init() {
      return new Promise((resolve, reject) => {
         this.http.get(`${environment.apiUrl}item/user`).toPromise()
           .then((response: any) => {
             const reqResponse = response || null;
             if (reqResponse && reqResponse.global && reqResponse.global.user) {

              const currentUser = response.global.user;


              const userIncluded = response.userincluded;
              let userCountry = userIncluded.filter((obj: any) => {
                return obj.type === 'country';
              });
              let userRegion = userIncluded.filter((obj: any) => {
                return obj.type === 'region';
              });
              let userCity = userIncluded.filter((obj: any) => {
                return obj.type === 'city';
              });
              userCountry = {
                id: userCountry[0].attributes.id,
                name: userCountry[0].attributes.name
              };
              userRegion = {
                id: userRegion[0].attributes.id,
                name: userRegion[0].attributes.name
              };
              userCity = {
                id: userCity[0].attributes.id,
                name: userCity[0].attributes.name
              };

              currentUser.country = userCountry;
              currentUser.region = userRegion;
              currentUser.city = userCity;
              console.log('STORED USER');
              console.log(currentUser);
              this.globalChangesService.setAccountDetails(currentUser);
              this.globalChangesService.setCart(JSON.parse(localStorage.getItem('products') || '[]'));
              this.globalChangesService.setTotalCart(JSON.parse(localStorage.getItem('totalCart') || '0'));
              resolve(true);
                    } else {
                      console.log('APP INIT USER NOT LOGGED IN');
                      this.globalChangesService.setCart(JSON.parse(localStorage.getItem('products') || '[]'));
                      this.globalChangesService.setTotalCart(JSON.parse(localStorage.getItem('totalCart') || '0'));
                      resolve(false);
            }
          })
          .catch(error => {
            console.log(error);
            localStorage.removeItem('currentUser');
            this.globalChangesService.setAccountDetails(null);
            this.globalChangesService.setCart(JSON.parse(localStorage.getItem('products') || '[]'));
            this.globalChangesService.setTotalCart(JSON.parse(localStorage.getItem('totalCart') || '0'));
          })

      });
    }
}
