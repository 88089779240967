<div class="page-content" style="min-height: 472px;">
    <div class="holder mt-0 py-3 py-sm-5 py-md-10 bg-cover fade-up-fast lazyloaded" data-bgset="../../assets/images/contact-africa.jpg" style="background-image: url('../../assets/images/contact-africa.jpg');">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9 col-xl-9">
            <div class="page-title-bg py-md-3">
              <h1 style="color:white;">How To Buy</h1>
              </div>
          </div>
        </div>
      </div>
    <picture style="display: none;"><source data-srcset="../../assets/images/contact-africa.jpg" sizes="1865px" srcset="../../assets/images/contact-africa.jpg"><img alt="" class="lazyautosizes lazyloaded" data-sizes="auto" data-parent-fit="cover" sizes="1865px"></picture></div>
    <div class="holder">
      <div class="container">
        <div class="row vert-margin">
          <div class="col-sm-9">
            <div class="title-wrap">
              <h2 class="h1-style">It`s simple to get your favourite original artworks or tickets</h2>
              <div>Select what product you wish to purchase, and the desired quantity. Click ‘Add To Cart’. When you complete your shopping, proceed to Check-Out by clicking on the Cart at the top right, and follow the instructions to complete your order. Please note that you may change the contents or edit the quantity of items in your shopping cart until you click ‘Place Order’, after which your order will be processed.
                If your order is accepted, a confirmation page will be displayed, and your order number will be provided. You will also receive an email confirmation after placing your order. If the email does not arrive withing 48 hours after placing the order, please contact us via email for assistance. You may track your order by using your tracking number provided in your shipping confirmation email. 
                For tickets for virtual tours or Museums and Art Galleries, click on ‘Museums’ or ‘Galleries’ and then click ‘DETAILS’ on your selection. Click ‘Add Ticket to Cart’ and then proceed to Check-Out by clicking on the Cart at the top right. Please following the instructions to complete your order and enjoy 24 hour </div>
            </div>
  <!--           <form data-toggle="validator" class="contact-form" id="contactForm" novalidate="novalidate">
              <div class="form-confirm">
                <div class="success-confirm">
                  Thanks! Your message has been sent. We will get back to you soon!
                </div>
                <div class="error-confirm">
                  Oops! There was an error submitting form. Refresh and send again.
                </div>
              </div>
              <div class="form-group">
                <div class="row vert-margin-middle">
                  <div class="col-lg">
                    <input type="text" name="name" class="form-control form-control--sm" placeholder="Name" required="">
                  </div>
                  <div class="col-lg">
                    <input type="text" name="lastName" class="form-control form-control--sm" placeholder="Last Name" required="">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row vert-margin-middle">
                  <div class="col-lg">
                    <input type="text" name="email" class="form-control form-control--sm" placeholder="Email" required="">
                  </div>
                  <div class="col-lg">
                    <input type="text" name="phone" class="form-control form-control--sm" placeholder="Phone" required="">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control form-control--sm textarea--height-200" name="message" placeholder="Message" required=""></textarea>
              </div>
              <button class="btn" type="submit">Send Message</button>
            </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  
